import React from 'react';
import Home from './components/home';
import ReactGA from 'react-ga4';

// Replace with your Measurement ID (from Google Analytics dashboard)
const GA_MEASUREMENT_ID = 'G-YY120GBPQ1';

ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {

  return (
    <>
      <Home />
    </>
  )
}

export default App;
