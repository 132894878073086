

import React from 'react'
import contact from '../../assets/img/contact/talking-contact.png';
import people from '../../assets/images/people.png';

export default function ContactInfo() {
  return (
    <>
      <section className="contact-infosectionv1 space-top overflow-hidden space-bottom">
        <div className="container">
          <div className="row g-lg-4 g-3 justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="contact-call-info">
                <div className="icon d-center">
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div className="co-box">
                  <h5 className="black">
                    Address
                  </h5>
                  <a href="#" className="pra">
                  Sarti Agri and Dairy Farms, Rewari<br /> Kosli ( 123302 )
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="contact-call-info">
                <div className="icon d-center">
                  <i className="fa-solid fa-phone"></i>
                </div>
                <div className="co-box">
                  <h5 className="black">
                    Lets Talk us
                  </h5>
                  <a href="#" className="pra">
                    Phone number: +91-8168024581
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".3s">
              <div className="contact-call-info">
                <div className="icon d-center">
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <div className="co-box">
                  <h5 className="black">
                    Send us email
                  </h5>
                  <a href="#" className="pra">
                    support@purityharvest.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="talking-section overflow-hidden">
        <div className="container">
          <div className="row g-4 align-items-xl-center flex-row-reverse justify-content-between">
            <div className="col-md-6">
              <div className="talking-contact-box">
                <div className="conatact-box common-contact-inner position-relative">
                  <div className="section-title mb-40">
                    <h5 className="p1-clr wow fadeInLeft" data-wow-delay="0.4s">
                      Contact
                    </h5>
                    <h2>
                      Get Touch Here
                    </h2>
                  </div>
                  <form onSubmit={(e) => e.preventDefault()} className="row g-xl-4 g-3">
                    <div className="col-lg-6">
                      <input type="text" placeholder="Name" />
                    </div>
                    <div className="col-lg-6">
                      <input type="email" placeholder="E-mail" />
                    </div>
                    <div className="col-lg-12">
                      <input type="text" placeholder="Subject" />
                    </div>
                    <div className="col-lg-12">
                      <textarea name="message" placeholder="Message"></textarea>
                    </div>
                    <div className="col-lg-6">
                      <button type="submit" className="cmn-btn text-capitalize">
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="taklking-cotnact-thumb w-100 wow fadeInRight" data-wow-delay=".4s">
                <img src={contact} alt="img" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
