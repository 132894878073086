import buffaloCreamRichBottle from "../assets/img/products/07.png";
import img08 from "../assets/img/products/08.png";
import img09 from "../assets/img/products/4.png";
import img11 from "../assets/img/products/11.png";
import img12 from "../assets/img/products/12.png";
import img13 from "../assets/img/products/13.png";
import paneer from "../assets/img/products/paneer.jpeg";

interface ProductDataType {
  id: number;
  title: string;
  size: string;
  mrp: number;
  type?: string;
  price: number;
  image: string;
  category: string;
  description: string;
  subDesc?: string;
  whyChoose?: { title: string; description: string }[]; // Added Why Choose
  keyBenefits?: { title: string; description: string }[]; // Added Key Benefits
}

const product_data:ProductDataType[] = [
  {
    id: 1,
    title: "Buffalo Cream Rich",
    size: '500 ml ',
    type: '( Pouch )',
    mrp: 38,
    price: 36,
    image: buffaloCreamRichBottle, 
    category: "Milk",
    description: "Loaded with calcium, protein, and essential vitamins (A, D, and B-complex)",
    subDesc: "Enjoy the richness of nature with our Buffalo Cream-Rich Milk, tailored for your family’s health and happiness. Taste the difference today!",
    whyChoose: [
      { title: "Authentic Flavor", description: "Experience the rich, creamy taste that only buffalo milk can provide." },
      { title: "Better Nutrition", description: "With higher fat and protein content, it’s an energy-rich choice for families, children, and individuals seeking a wholesome lifestyle." },
      { title: "Traditionally Loved", description: "Ideal for preparing traditional recipes that call for creamy, thick milk." }
    ],
    keyBenefits: [
      { title: "Rich & Creamy", description: "The higher fat content ensures a luxuriously smooth texture, making it ideal for both drinking and cooking." },
      { title: "Nutrient-Packed", description: "Loaded with calcium, protein, and essential vitamins (A, D, and B-complex), supporting strong bones, muscles, and overall health." },
      { title: "Pure & Natural", description: "Sourced from well-cared-for buffaloes, our milk is free from preservatives or additives, ensuring a wholesome experience." },
      { title: "Versatile", description: "Perfect for direct consumption, making tea, coffee, or preparing dairy products like yogurt, paneer, and desserts." }
    ]
  },
  {
    id: 2,
    title: "Buffalo Cream Rich",
    size: '1 litre',
    type: '( Glass Bottle )',
    mrp: 82,
    price: 76,
    image: img08,
    category: "Milk",
    description: "Loaded with calcium, protein, and essential vitamins (A, D, and B-complex)",
    subDesc: "Enjoy the richness of nature with our Buffalo Cream-Rich Milk, tailored for your family’s health and happiness. Taste the difference today!",
    whyChoose: [
      { title: "Authentic Flavor", description: "Experience the rich, creamy taste that only buffalo milk can provide." },
      { title: "Better Nutrition", description: "With higher fat and protein content, it’s an energy-rich choice for families, children, and individuals seeking a wholesome lifestyle." },
      { title: "Traditionally Loved", description: "Ideal for preparing traditional recipes that call for creamy, thick milk." }
    ],
    keyBenefits: [
      { title: "Rich & Creamy", description: "The higher fat content ensures a luxuriously smooth texture, making it ideal for both drinking and cooking." },
      { title: "Nutrient-Packed", description: "Loaded with calcium, protein, and essential vitamins (A, D, and B-complex), supporting strong bones, muscles, and overall health." },
      { title: "Pure & Natural", description: "Sourced from well-cared-for buffaloes, our milk is free from preservatives or additives, ensuring a wholesome experience." },
      { title: "Versatile", description: "Perfect for direct consumption, making tea, coffee, or preparing dairy products like yogurt, paneer, and desserts." }
    ]
  },
  {
    id: 3,
    title: "Buffalo Bliss",
    size: '500 ml',
    type: '( Pouch )',
    mrp: 42,
    price: 39,
    image: img09,
    category: "Milk",
    description: "Perfect for growing children, active adults, and health-conscious individuals.",
    subDesc: "A Sip of Bliss in Every Glass, whether you're seeking energy for a busy day or a touch of indulgence in your routine, Buffalo Bliss High-Fat A2 Milk is the perfect companion. Taste the difference, feel the nourishment, and enjoy the bliss!",
    whyChoose: [
      { title: "Rich in A2 Protein", description: "Gentle on the stomach and easy to digest, A2 protein is ideal for those with sensitivity to regular milk." },
      { title: "High in Creamy Goodness", description: "With a naturally high fat content, our milk is luxuriously thick and perfect for creating decadent dairy treats or enjoying straight from the glass." },
      { title: "Nutrient Powerhouse", description: "Packed with essential calcium, protein, and vitamins A & D, every sip nourishes your body and supports strong bones and a healthy lifestyle." },
      { title: "Farm Fresh & Pure", description: "We prioritize purity, ensuring our milk is free from additives, preservatives, and hormones." },
    ],
    keyBenefits: [
      { title: "Exceptional Flavor", description: "Its creamy, full-bodied texture enhances everything from morning tea and coffee to smoothies and desserts." },
      { title: "Wholesome Nutrition", description: "Perfect for growing children, active adults, and health-conscious individuals." },
      { title: "Multi-Use Versatility", description: "Ideal for making ghee, paneer, yogurt, and traditional recipes." },
    ]
  },
  {
    id: 4,
    title: "Buffalo Bliss",
    size: '1 litre',
    type: '( Glass Bottle )',
    mrp: 88,
    price: 82,
    image: buffaloCreamRichBottle, 
    category: "Milk",
    description: "Perfect for growing children, active adults, and health-conscious individuals.",
    subDesc: "A Sip of Bliss in Every Glass, whether you're seeking energy for a busy day or a touch of indulgence in your routine, Buffalo Bliss High-Fat A2 Milk is the perfect companion. Taste the difference, feel the nourishment, and enjoy the bliss!",
    whyChoose: [
      { title: "Rich in A2 Protein", description: "Gentle on the stomach and easy to digest, A2 protein is ideal for those with sensitivity to regular milk." },
      { title: "High in Creamy Goodness", description: "With a naturally high fat content, our milk is luxuriously thick and perfect for creating decadent dairy treats or enjoying straight from the glass." },
      { title: "Nutrient Powerhouse", description: "Packed with essential calcium, protein, and vitamins A & D, every sip nourishes your body and supports strong bones and a healthy lifestyle." },
      { title: "Farm Fresh & Pure", description: "We prioritize purity, ensuring our milk is free from additives, preservatives, and hormones." },
    ],
    keyBenefits: [
      { title: "Exceptional Flavor", description: "Its creamy, full-bodied texture enhances everything from morning tea and coffee to smoothies and desserts." },
      { title: "Wholesome Nutrition", description: "Perfect for growing children, active adults, and health-conscious individuals." },
      { title: "Multi-Use Versatility", description: "Ideal for making ghee, paneer, yogurt, and traditional recipes." },
    ]
  },
  {
    id: 5,
    title: "Cow Bliss",
    size: '500 ml',
    type: '( Pouch )',
    mrp: 35,
    price: 33,
    image: img11, 
    category: "Milk",
    description: "Perfect for tea, coffee, smoothies, cereals, baking, and cooking.",
    subDesc: "Goodness in Every Glass Cow Bliss Whole Milk isn’t just milk—it’s a way to nourish your body, delight your taste buds, and enhance your everyday meals. From breakfast to bedtime, our milk brings joy and wellness to your table.",
    whyChoose: [
      { title: "100% Pure Whole Milk", description: "Naturally rich and full-bodied, perfect for enjoying as is or enhancing your favorite recipes." },
      { title: "Nutrient-Dense", description: "Packed with protein, calcium, and vitamins A & D to support strong bones, muscles, and overall well-being." },
      { title: "Creamy, Delicious Flavor", description: "Its smooth texture and slightly sweet taste make it a favorite for everyone in the family." },
      { title: "Farm Fresh Quality", description: "Sourced from healthy, well-cared-for cows and free from artificial additives, preservatives, and hormones." },
    ],
    keyBenefits: [
      { title: "Complete Nutrition", description: "Whole milk provides a wholesome source of energy, making it ideal for growing kids, active adults, and seniors." },
      { title: "Versatile Uses", description: "Perfect for tea, coffee, smoothies, cereals, baking, and cooking." },
      { title: "Trusted Purity", description: " Freshly pasteurized to maintain its natural goodness while ensuring safety and quality." },
    ]
  },
  {
    id: 6,
    title: "Cow Bliss",
    size: '1 litre',
    type: '( Glass Bottle )',
    mrp: 74,
    price: 70,
    image: img12, 
    category: "Milk",
    description: "Perfect for tea, coffee, smoothies, cereals, baking, and cooking.",
    subDesc: "Goodness in Every Glass Cow Bliss Whole Milk isn’t just milk—it’s a way to nourish your body, delight your taste buds, and enhance your everyday meals. From breakfast to bedtime, our milk brings joy and wellness to your table.",
    whyChoose: [
      { title: "100% Pure Whole Milk", description: "Naturally rich and full-bodied, perfect for enjoying as is or enhancing your favorite recipes." },
      { title: "Nutrient-Dense", description: "Packed with protein, calcium, and vitamins A & D to support strong bones, muscles, and overall well-being." },
      { title: "Creamy, Delicious Flavor", description: "Its smooth texture and slightly sweet taste make it a favorite for everyone in the family." },
      { title: "Farm Fresh Quality", description: "Sourced from healthy, well-cared-for cows and free from artificial additives, preservatives, and hormones." },
    ],
    keyBenefits: [
      { title: "Complete Nutrition", description: "Whole milk provides a wholesome source of energy, making it ideal for growing kids, active adults, and seniors." },
      { title: "Versatile Uses", description: "Perfect for tea, coffee, smoothies, cereals, baking, and cooking." },
      { title: "Trusted Purity", description: " Freshly pasteurized to maintain its natural goodness while ensuring safety and quality." },
    ]
  },
  // data repeat
  {
    id: 7,
    title: "Desi Bilona Ghee Cow",
    size: '1000 ml',
    mrp: 1250,
    price: 990,
    image: img13,
    category: "Ghee",
    description: "Made using the ancient hand-churned method, where curd is churned to extract butter",
  },
  {
    id: 8,
    title: "Desi Bilona Ghee Buffalo",
    size: '1000 ml',
    mrp: 1150,
    price: 890,
    image: img13, 
    category: "Ghee",
    description: "Made by churning curd from pure, high-fat buffalo milk, ensuring authenticity ",
  },
  {
    id: 9,
    title: "Paneer",
    size: '250 gm',
    mrp: 95,
    price: 85,
    image: paneer,
    category: "Milk Products",
    description: "Made from high-fat buffalo milk, resulting in a soft, rich, and creamy paneer",
  }
]

export default product_data