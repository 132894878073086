import React, { useState } from 'react'
import faq_data from '../../data/faq_data';
import faq from '../../assets/img/element/faq-element.png';

export default function Faq() {
  const [isOpen, setIsOpen] = useState(0);

  const toggle = (i: number) => {
    setIsOpen(i === isOpen ? 0 : i);
  }

  return (
    <>
      <section className="faq-section section-padding p100-bg">
        <div className="container">
          <div className="row g-md-4 g-2 align-items-center justify-content-between">
            <div className="col-lg-5 col-md-6">
              <div className="faq-content-left">
                <div className="section-title mb-40">
                  <h5 className="p1-clr wow fadeInLeft text-uppercase" data-wow-delay="0.4s">
                    FAQ
                  </h5>
                  <h2 className="wow fadeInDown" data-wow-delay=".3s">
                  Product Quality & Safety
                  </h2>
                  <p>
                  Understand our quality assurance process, safety measures, and how we ensure you get the freshest milk every day. Our milk undergoes rigorous testing for purity, freshness, and hygiene at every stage of the supply chain. From farm-to-doorstep, we maintain cold chain logistics to preserve quality and freshness, ensuring every sip is as nutritious and delicious as nature intended.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 order-md-0 order-1">
              <div className="tab-faq faq">
                <div className="accordion-section d-grid gap-xxl-4 gap-lg-3 gap-2">

                  {faq_data.map((item, i) => (
                    <div key={i} className={`accordion-single ${isOpen === i ? "active" : ""}`}>
                      <h5 className="header-area" onClick={() => toggle(i)}>
                        <button
                          className="accordion-btn d-flex align-items-center d-flex position-relative w-100"
                          type="button">
                          {item.question}
                        </button>
                      </h5>
                      <div className="content-area" style={{ display: isOpen === i ? "block" : "none" }}>
                        <div className="content-body">
                          <p>
                            {item.answer}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <img src={faq} alt="img" className="faq-element" />
      </section>
    </>
  )
}
