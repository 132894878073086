import React, { useState } from 'react'
import product_data from '../../data/product_data';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const categories = ["All", ...Array.from(new Set(product_data.map((item) => item.category)))];

export default function ProductHome() {

  const [activeCategory, setActiveCategory] = useState("All");
  const [items, setItems] = useState(product_data);

  const filterItems = (cateItem: string) => {
    setActiveCategory(cateItem);
    if (cateItem === "All") {
      return setItems(product_data);
    } else {
      const findItems = product_data.filter((findItem) => {
        return findItem.category === cateItem;
      });
      setItems(findItems);
    }
  };

  const handleButtonClick = (id: number) => {
    ReactGA.event({
      category: 'Button',
      action: `Home Order Now ${id}`,
      label: 'Order Now'
    });
  };

  return (
    <div className='product-home'>
      <section className='service-feature-section white-bg space-top'>
        <div className="container">
          {/* Title Section */}
          <div className="row g-4 justify-content-between align-items-end mb-60">
            <div className="col-xxl-5 col-xl-7">
              <div className="section-title">
                <h5 className="p1-clr wow fadeInLeft" data-wow-delay="0.4s">
                  Our Products
                </h5>
                <h2 className="wow fadeInDown" data-wow-delay=".3s">
                  Nourishing the world from farm to table
                </h2>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5">
              <div className="mixtup-filtering d-flex justify-content-end">
                <div className="filter-btns">
                  {categories.map((cate, i) => (
                    <React.Fragment key={i}>
                      <button
                        onClick={() => filterItems(cate)}
                        className={`filter-btn ${cate === activeCategory ? "active-filter" : ""}`}
                      >
                        {cate}
                      </button>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Product Cards */}
          <div className="product-grid">
            {items.map((item, i) => (
              <div key={i} className="product-card">
                <img src={item.image} alt={item.title} className="product-image" />
                <div className="product-content">
                  <h3 className="product-title">{item.title}</h3>
                  <p className="product-description">{item.size} <span className='product-type'> {item.type} </span></p>
                  <div className="product-price-wrapper">
                    <span className="product-mrp">Rs {item.mrp}</span>
                    <span className="product-price">Rs {item.price}</span>
                    <span className="tax-text">* inclusive of all taxes</span>
                  </div>
                  <div className='product-cta'>
                  <Link
                    to={`/product-details/${item.id}`}
                    onClick={() => handleButtonClick(item.id)}
                    className="order-now-btn"
                  >
                    Order Now
                  </Link>
                  {/* <Link
                    to={`/product-details/${item.id}`}
                    onClick={() => handleButtonClick(item.id)}
                    className="subscribe-btn"
                  >
                    Subscribe
                  </Link> */}
                    </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </section>
    </div>
  )
}