
import React from 'react'
import HeaderThree from '../../layouts/headers/HeaderThree';
import Breadcrumnd from '../common/Breadcrumnd';
import AboutPage from './AboutPage';
import Subscribe from './Subscribe';
import Wrapper from '../../layouts/Wrapper';

export default function About() {
    return (
        <Wrapper>
            <HeaderThree />
            <Breadcrumnd title="About Us" subtitle="About Us" />
            <AboutPage />
            {/* <Subscribe /> */}
        </Wrapper>
    )
}
