import React from 'react';
import menu_data from '../../../data/menu_data';
import { Link } from 'react-router-dom';

interface NavMenuProps {
  sticky?: boolean; // Accepts sticky as a boolean prop
}

export default function NavMenu({ sticky }: NavMenuProps) {
  return (
    <>
      <ul>
        {menu_data.map((item, i) => (
          <li key={i} className={`${item.has_dropdown ? "has-dropdown active menu-thumb" : ""}`}>
            <Link 
              to={item.link} 
              style={{
                color: sticky ? 'white' : '#333', // Blue if sticky, otherwise gray
                transition: 'color 0.3s ease' // Smooth color transition
              }}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}