
import React from 'react';
import HeaderThree from '../../layouts/headers/HeaderThree';
import Breadcrumnd from '../common/Breadcrumnd';
import ProductListArea from './ProductList';
import Wrapper from '../../layouts/Wrapper';
import Subscribe from '../about/Subscribe';

export default function Product() {
  return (
    <Wrapper>
      <HeaderThree />
      <Breadcrumnd title="Product Catalog" subtitle="Discover Your Needs" />
      <ProductListArea />
      {/* <Subscribe /> */}
    </Wrapper>
  )
}
