import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShoppingCart, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../context/CartContext';
import { Offcanvas, Button, Form } from 'react-bootstrap';
import emptyCart from '../../assets/img/cart/shopping-bag.png';
import { useNavigate } from 'react-router-dom';


type CartFloatingButtonProps = {
  onToggleOffcanvas: (isOpen: boolean) => void; // Prop type definition
};

type SubArea = {
  hub_id: string | number;
  sub_area_id: string | number;
  subarea_name: string;
};

export default function CartFloatingButton({ onToggleOffcanvas }: CartFloatingButtonProps) {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [placement, setPlacement] = useState<'bottom' | 'end'>('bottom');
  const [view, setView] = useState<'cart' | 'address'>('cart'); // Toggle between cart and address view
  const { cart, removeFromCart, clearCart, incrementQuantity, decrementQuantity } = useCart();
  const [selectedArea, setSelectedArea] = useState('');
  const [subareas, setSubareas] = useState<SubArea[]>([]);
  const navigate = useNavigate();

  const areaData = [
    { area_id: "", area_name: "Select Area" },
    { area_id: 454, area_name: "Ashok Vihar Phase 2" },
    { area_id: 455, area_name: "Ashok Vihar Phase 1" },
    { area_id: 456, area_name: "Ashok Vihar Phase 3" },
    { area_id: 458, area_name: "DLF City Phase 2" },
    { area_id: 459, area_name: "DLF City Phase 3" },
    { area_id: 460, area_name: "DLF City Phase 4" },
    { area_id: 461, area_name: "DLF City Phase 1" },
    { area_id: 462, area_name: "DLF City Phase 5" },
    { area_id: 463, area_name: "DLF Cyber City" },
    { area_id: 465, area_name: "Golf Course Extension Road" },
    { area_id: 466, area_name: "Golf Course Road" },
    { area_id: 467, area_name: "Gurgaon Faridabad Road" },
    { area_id: 468, area_name: "Gwal Pahari" },
    { area_id: 474, area_name: "Nirvana Country" },
    { area_id: 475, area_name: "Old Delhi Gurgaon Road" },
    { area_id: 476, area_name: "Patel Nagar" },
    { area_id: 477, area_name: "Roshan Pura" },
    { area_id: 478, area_name: "Sector 1" },
    { area_id: 479, area_name: "Sector 1A" },
    { area_id: 480, area_name: "Sector 1B" },
    { area_id: 481, area_name: "Sector 1C" },
    { area_id: 482, area_name: "Sector 1D" },
    { area_id: 483, area_name: "Sector 10" },
    { area_id: 484, area_name: "Sector 11" },
    { area_id: 485, area_name: "Sector 12" },
    { area_id: 498, area_name: "Palam Vihar (Sector 2)" },
    { area_id: 499, area_name: "Sector 3" },
    { area_id: 500, area_name: "Sector 3A" },
    { area_id: 501, area_name: "New Palam Vihar (Sector 3)" },
    { area_id: 502, area_name: "Sector 4" },
    { area_id: 503, area_name: "Sector 5" },
    { area_id: 504, area_name: "Sector 6" },
    { area_id: 505, area_name: "Sector 6A" },
    { area_id: 506, area_name: "Sector 7" },
    { area_id: 507, area_name: "Sector 8" },
    { area_id: 508, area_name: "Sector 9" },
    { area_id: 509, area_name: "Sector 9A" },
    { area_id: 510, area_name: "Sector 9B" },
    { area_id: 511, area_name: "Sector 10A" },
    { area_id: 512, area_name: "Sector 12A" },
    { area_id: 513, area_name: "Sector 13" },
    { area_id: 514, area_name: "Sector 14" },
    { area_id: 515, area_name: "Sector 15" },
    { area_id: 516, area_name: "Sector 16" },
    { area_id: 517, area_name: "Sector 17" },
    { area_id: 518, area_name: "Sector 18" },
    { area_id: 519, area_name: "Sector 19" },
    { area_id: 520, area_name: "Sector 20" },
    { area_id: 521, area_name: "Sector 21" },
    { area_id: 522, area_name: "Sector 22" },
    { area_id: 523, area_name: "Sector 23" },
    { area_id: 524, area_name: "Sector 23A" },
    { area_id: 525, area_name: "Sector 24" },
    { area_id: 526, area_name: "Sector 25" },
    { area_id: 527, area_name: "Sector 26" },
    { area_id: 528, area_name: "Sector 26A" },
    { area_id: 529, area_name: "Sector 27" },
    { area_id: 530, area_name: "Sector 28" },
    { area_id: 531, area_name: "Sector 29" },
    { area_id: 532, area_name: "Sector 30" },
    { area_id: 533, area_name: "Sector 31" },
    { area_id: 534, area_name: "Sector 32" },
    { area_id: 535, area_name: "Sector 33" },
    { area_id: 536, area_name: "Sector 34" },
    { area_id: 537, area_name: "Sector 35" },
    { area_id: 538, area_name: "Sector 36" },
    { area_id: 539, area_name: "Sector 37" },
    { area_id: 540, area_name: "Sector 37A" },
    { area_id: 541, area_name: "Sector 37B" },
    { area_id: 542, area_name: "Sector 37C" },
    { area_id: 543, area_name: "Sector 37D" },
    { area_id: 637, area_name: "Sohna Road" },
    { area_id: 638, area_name: "South City 1" },
    { area_id: 639, area_name: "South City 2" },
    { area_id: 640, area_name: "Sushant Lok Extension" },
    { area_id: 641, area_name: "Sushant Lok Phase 1" },
    { area_id: 642, area_name: "Sushant Lok Phase 2" },
    { area_id: 643, area_name: "Sushant Lok Phase 3" },
    { area_id: 644, area_name: "Udyog Vihar Phase 1" },
    { area_id: 645, area_name: "Udyog Vihar Phase 2" },
    { area_id: 646, area_name: "Udyog Vihar Phase 3" },
    { area_id: 647, area_name: "Udyog Vihar Phase 4" },
    { area_id: 648, area_name: "Udyog Vihar Phase 5" },
    { area_id: 652, area_name: "Sector 15 Part 2" },
    { area_id: 797, area_name: "Sector 91" },
    { area_id: 879, area_name: "Sector 17A" },
    { area_id: 966, area_name: "Sector 17C" }
  ];

  const subAreaData: Record<string, SubArea[]> = {
    454: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1186,
        subarea_name: "Ashok Vihar Phase 2"
      }
    ],
    455: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1187,
        subarea_name: "A1 Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1188,
        subarea_name: "Bharat Residency"
      },
      {
        hub_id: 4,
        sub_area_id: 1189,
        subarea_name: "Sheetla Enclave"
      },
      {
        hub_id: 4,
        sub_area_id: 2712,
        subarea_name: "Ashok Vihar Phase 1"
      }
    ],
    456: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1190,
        subarea_name: "C2 Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1191,
        subarea_name: "Park View Residency"
      },
      {
        hub_id: 4,
        sub_area_id: 1192,
        subarea_name: "Satya Hemitage"
      },
      {
        hub_id: 4,
        sub_area_id: 1193,
        subarea_name: "U Block"
      },
      {
        hub_id: 4,
        sub_area_id: 2713,
        subarea_name: "Ashok Vihar Phase 3"
      }
    ],
    458: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1194,
        subarea_name: "Akashneem Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1195,
        subarea_name: "Bbm Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1196,
        subarea_name: "Belvedere Tower"
      },
      {
        hub_id: 3,
        sub_area_id: 1197,
        subarea_name: "Beverly Park 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1198,
        subarea_name: "Beverly Park 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1199,
        subarea_name: "Block K 19"
      },
      {
        hub_id: 3,
        sub_area_id: 1200,
        subarea_name: "Chakkarpur"
      },
      {
        hub_id: 3,
        sub_area_id: 1201,
        subarea_name: "Gulmohar Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1202,
        subarea_name: "Gulmoher Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1203,
        subarea_name: "J Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1204,
        subarea_name: "Jacaranda Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1205,
        subarea_name: "K Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1206,
        subarea_name: "L Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1207,
        subarea_name: "M Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1208,
        subarea_name: "M4 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1209,
        subarea_name: "Madhya Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1210,
        subarea_name: "Mg Road"
      },
      {
        hub_id: 3,
        sub_area_id: 1211,
        subarea_name: "N Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1212,
        subarea_name: "Oakwood Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1213,
        subarea_name: "Owkwood Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1214,
        subarea_name: "P Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1215,
        subarea_name: "Q Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1216,
        subarea_name: "The Villas"
      },
      {
        hub_id: 3,
        sub_area_id: 1217,
        subarea_name: "U Block"
      },
      {
        hub_id: 3,
        sub_area_id: 2715,
        subarea_name: "DLF City Phase 2"
      }
    ],
    459: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1218,
        subarea_name: "Ambience Lagoon Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1219,
        subarea_name: "Belvedere Park"
      },
      {
        hub_id: 3,
        sub_area_id: 1220,
        subarea_name: "BPB Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1221,
        subarea_name: "BPD Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1222,
        subarea_name: "Catriona Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1223,
        subarea_name: "Cmc Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 1224,
        subarea_name: "Corporate Stays"
      },
      {
        hub_id: 3,
        sub_area_id: 1225,
        subarea_name: "DLF City Phase 3"
      },
      {
        hub_id: 3,
        sub_area_id: 1226,
        subarea_name: "Garden Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1227,
        subarea_name: "Guru Dronacharya Metro"
      },
      {
        hub_id: 3,
        sub_area_id: 1228,
        subarea_name: "Island Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1229,
        subarea_name: "Lagune Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1230,
        subarea_name: "Moulsari Avenue"
      },
      {
        hub_id: 3,
        sub_area_id: 1231,
        subarea_name: "National Media Center"
      },
      {
        hub_id: 3,
        sub_area_id: 1232,
        subarea_name: "Pink Town House"
      },
      {
        hub_id: 3,
        sub_area_id: 1233,
        subarea_name: "S Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1234,
        subarea_name: "Shankar Chowk"
      },
      {
        hub_id: 3,
        sub_area_id: 1235,
        subarea_name: "Shanti Residence"
      },
      {
        hub_id: 3,
        sub_area_id: 1236,
        subarea_name: "Siris Road"
      },
      {
        hub_id: 3,
        sub_area_id: 1237,
        subarea_name: "U Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1238,
        subarea_name: "V Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1239,
        subarea_name: "Yadav Apartment"
      }
    ],
    460: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1240,
        subarea_name: "A1 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1241,
        subarea_name: "Aster Avenue"
      },
      {
        hub_id: 3,
        sub_area_id: 1242,
        subarea_name: "Chander Lok"
      },
      {
        hub_id: 3,
        sub_area_id: 1243,
        subarea_name: "DLF City Phase 4"
      },
      {
        hub_id: 3,
        sub_area_id: 1244,
        subarea_name: "Green Avenue"
      },
      {
        hub_id: 3,
        sub_area_id: 1245,
        subarea_name: "Green Meadows"
      },
      {
        hub_id: 3,
        sub_area_id: 1246,
        subarea_name: "Lotus Villas"
      },
      {
        hub_id: 3,
        sub_area_id: 1247,
        subarea_name: "Orchid Crescent"
      },
      {
        hub_id: 3,
        sub_area_id: 1248,
        subarea_name: "Pragati Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1249,
        subarea_name: "Regency Park 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1250,
        subarea_name: "Regency Park 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1251,
        subarea_name: "Regency Park Ii"
      },
      {
        hub_id: 3,
        sub_area_id: 1252,
        subarea_name: "Regency Park Road"
      },
      {
        hub_id: 3,
        sub_area_id: 1253,
        subarea_name: "Regwood Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1254,
        subarea_name: "Richmond Park"
      },
      {
        hub_id: 3,
        sub_area_id: 1255,
        subarea_name: "Ridgewood Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1256,
        subarea_name: "Windsor Court"
      }
    ],
    461: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1257,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1258,
        subarea_name: "Arjun Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1259,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1260,
        subarea_name: "D Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1261,
        subarea_name: "E Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1262,
        subarea_name: "G Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1263,
        subarea_name: "H Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1264,
        subarea_name: "Itc Housing"
      },
      {
        hub_id: 3,
        sub_area_id: 1265,
        subarea_name: "Jeevan Park"
      },
      {
        hub_id: 3,
        sub_area_id: 1266,
        subarea_name: "Kachnar Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1267,
        subarea_name: "Paschim Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 1268,
        subarea_name: "Silver Oaks"
      },
      {
        hub_id: 3,
        sub_area_id: 1269,
        subarea_name: "Sukh Chain Marg"
      },
      {
        hub_id: 3,
        sub_area_id: 2716,
        subarea_name: "DLF City Phase 1"
      }
    ],
    462: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1270,
        subarea_name: "Carlton Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1271,
        subarea_name: "Carlton Estate 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1272,
        subarea_name: "Carlton Estate 4"
      },
      {
        hub_id: 3,
        sub_area_id: 1273,
        subarea_name: "Cartlon Estate 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1274,
        subarea_name: "Club Drive"
      },
      {
        hub_id: 3,
        sub_area_id: 1275,
        subarea_name: "DLF City Phase 5"
      },
      {
        hub_id: 3,
        sub_area_id: 1276,
        subarea_name: "DLF Park Place"
      },
      {
        hub_id: 3,
        sub_area_id: 1277,
        subarea_name: "DLF Summit Park Drive"
      },
      {
        hub_id: 3,
        sub_area_id: 1278,
        subarea_name: "Excusive Floors"
      },
      {
        hub_id: 3,
        sub_area_id: 1279,
        subarea_name: "Icon Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1280,
        subarea_name: "Mangolia"
      },
      {
        hub_id: 3,
        sub_area_id: 1281,
        subarea_name: "Mig Flats"
      },
      {
        hub_id: 3,
        sub_area_id: 1282,
        subarea_name: "Parkplace Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1283,
        subarea_name: "Parsvnath Exotica"
      },
      {
        hub_id: 3,
        sub_area_id: 1284,
        subarea_name: "Plot 138"
      },
      {
        hub_id: 3,
        sub_area_id: 1285,
        subarea_name: "Royalton Tower"
      },
      {
        hub_id: 3,
        sub_area_id: 1286,
        subarea_name: "Summit Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1287,
        subarea_name: "Tdi Ourinia"
      },
      {
        hub_id: 3,
        sub_area_id: 1288,
        subarea_name: "The Belaire Condominiums"
      },
      {
        hub_id: 3,
        sub_area_id: 1289,
        subarea_name: "The Crest"
      },
      {
        hub_id: 3,
        sub_area_id: 1290,
        subarea_name: "The Icon"
      },
      {
        hub_id: 3,
        sub_area_id: 1291,
        subarea_name: "The Pinnacle"
      },
      {
        hub_id: 3,
        sub_area_id: 1292,
        subarea_name: "Trinity Towers"
      },
      {
        hub_id: 3,
        sub_area_id: 1293,
        subarea_name: "Wellington Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1294,
        subarea_name: "Wellington Estate 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1295,
        subarea_name: "Westend Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1296,
        subarea_name: "Western Hight"
      },
      {
        hub_id: 3,
        sub_area_id: 3061,
        subarea_name: "Princeton Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 3497,
        subarea_name: "DLF pinnacle society"
      }
    ],
    463: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1297,
        subarea_name: "Belvedere Park"
      },
      {
        hub_id: 3,
        sub_area_id: 2717,
        subarea_name: "DLF Cyber City"
      }
    ],
    465: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1298,
        subarea_name: "Bestech Park View Spa Next"
      },
      {
        hub_id: 3,
        sub_area_id: 1299,
        subarea_name: "DLF Aralias"
      },
      {
        hub_id: 3,
        sub_area_id: 1300,
        subarea_name: "Emaar Emerald Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1301,
        subarea_name: "Emaar Emerald Hills"
      },
      {
        hub_id: 3,
        sub_area_id: 1302,
        subarea_name: "Emaar Palm Drive"
      },
      {
        hub_id: 3,
        sub_area_id: 1303,
        subarea_name: "Heritage One"
      },
      {
        hub_id: 3,
        sub_area_id: 1304,
        subarea_name: "Ireo Skyon"
      },
      {
        hub_id: 3,
        sub_area_id: 1305,
        subarea_name: "Ireo The Grand Arch"
      },
      {
        hub_id: 3,
        sub_area_id: 1306,
        subarea_name: "M3M Merlin"
      },
      {
        hub_id: 3,
        sub_area_id: 1307,
        subarea_name: "Unitech Espace"
      },
      {
        hub_id: 3,
        sub_area_id: 2719,
        subarea_name: "Golf Course Extension Road"
      }
    ],
    466: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1308,
        subarea_name: "Central Park 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1309,
        subarea_name: "DLF Park Place"
      },
      {
        hub_id: 3,
        sub_area_id: 1310,
        subarea_name: "Emmar Palm Terrace"
      },
      {
        hub_id: 3,
        sub_area_id: 1311,
        subarea_name: "Mangolia"
      },
      {
        hub_id: 3,
        sub_area_id: 1312,
        subarea_name: "Palm Spring"
      },
      {
        hub_id: 3,
        sub_area_id: 1313,
        subarea_name: "Parsvnath Exotica"
      },
      {
        hub_id: 3,
        sub_area_id: 1314,
        subarea_name: "Summit Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1315,
        subarea_name: "Suncity Market"
      },
      {
        hub_id: 3,
        sub_area_id: 1316,
        subarea_name: "TDI Ourania"
      },
      {
        hub_id: 3,
        sub_area_id: 1317,
        subarea_name: "The Palm Springs"
      },
      {
        hub_id: 3,
        sub_area_id: 1318,
        subarea_name: "Vipul Belmonte"
      },
      {
        hub_id: 3,
        sub_area_id: 2720,
        subarea_name: "Golf Course Road"
      }
    ],
    467: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1319,
        subarea_name: "Valley View Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 2721,
        subarea_name: "Gurgaon Faridabad Road"
      }
    ],
    468: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1320,
        subarea_name: "Ansal Valley View Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1321,
        subarea_name: "Sujjan Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1322,
        subarea_name: "Valley View Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 2722,
        subarea_name: "Gwal Pahari"
      }
    ],
    474: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1323,
        subarea_name: "Aspen Greens"
      },
      {
        hub_id: 3,
        sub_area_id: 1324,
        subarea_name: "Birch Court"
      },
      {
        hub_id: 3,
        sub_area_id: 1325,
        subarea_name: "The Close North"
      },
      {
        hub_id: 3,
        sub_area_id: 1326,
        subarea_name: "The Close South"
      },
      {
        hub_id: 3,
        sub_area_id: 1327,
        subarea_name: "Deerwood Chase"
      },
      {
        hub_id: 3,
        sub_area_id: 1328,
        subarea_name: "Escape Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1329,
        subarea_name: "Espace"
      },
      {
        hub_id: 3,
        sub_area_id: 1330,
        subarea_name: "J Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1331,
        subarea_name: "The Woodstock Floors"
      },
      {
        hub_id: 3,
        sub_area_id: 1332,
        subarea_name: "Unitech Espace"
      },
      {
        hub_id: 3,
        sub_area_id: 1333,
        subarea_name: "Unitech Fresco"
      },
      {
        hub_id: 3,
        sub_area_id: 1334,
        subarea_name: "Unitech Woodstock Floors"
      },
      {
        hub_id: 3,
        sub_area_id: 2728,
        subarea_name: "Nirvana Country"
      }
    ],
    475: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1335,
        subarea_name: "Rajiv Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 1336,
        subarea_name: "Sanjay Gram"
      },
      {
        hub_id: 4,
        sub_area_id: 2729,
        subarea_name: "Old Delhi Gurgaon Road"
      }
    ],
    476: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1337,
        subarea_name: "Civil Lines"
      },
      {
        hub_id: 4,
        sub_area_id: 1338,
        subarea_name: "Patel Nagar"
      }
    ],
    477: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1339,
        subarea_name: "New Basti"
      },
      {
        hub_id: 4,
        sub_area_id: 1340,
        subarea_name: "Roshan Pura"
      }
    ],
    478: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2730,
        subarea_name: "Sector 1"
      }
    ],
    479: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2731,
        subarea_name: "Sector 1A"
      }
    ],
    480: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2732,
        subarea_name: "Sector 1B"
      }
    ],
    481: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2733,
        subarea_name: "Sector 1C"
      }
    ],
    482: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2734,
        subarea_name: "Sector 1D"
      }
    ],
    483: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1341,
        subarea_name: "Bhawani Enclave"
      },
      {
        hub_id: 4,
        sub_area_id: 1342,
        subarea_name: "Shivaji Nager"
      },
      {
        hub_id: 4,
        sub_area_id: 2735,
        subarea_name: "Sector 10"
      }
    ],
    484: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1343,
        subarea_name: "Jacob Pura"
      },
      {
        hub_id: 4,
        sub_area_id: 1344,
        subarea_name: "Mianwali Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 1345,
        subarea_name: "Shivaji Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 2736,
        subarea_name: "Sector 11"
      }
    ],
    485: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2737,
        subarea_name: "Sector 12"
      }
    ],
    498: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1346,
        subarea_name: "A Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1347,
        subarea_name: "Ansal Palam Vihar"
      },
      {
        hub_id: 4,
        sub_area_id: 1348,
        subarea_name: "Ansal Plaza"
      },
      {
        hub_id: 4,
        sub_area_id: 1349,
        subarea_name: "Ashok Vihar Phase 3"
      },
      {
        hub_id: 4,
        sub_area_id: 1350,
        subarea_name: "B Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1351,
        subarea_name: "Balaji Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1352,
        subarea_name: "Bestech Park View Residency"
      },
      {
        hub_id: 4,
        sub_area_id: 1353,
        subarea_name: "Bestech Park View Spa"
      },
      {
        hub_id: 4,
        sub_area_id: 1354,
        subarea_name: "Block 1"
      },
      {
        hub_id: 4,
        sub_area_id: 1355,
        subarea_name: "C Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1356,
        subarea_name: "C2 Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1357,
        subarea_name: "C1 Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1358,
        subarea_name: "Celebrity Homes"
      },
      {
        hub_id: 4,
        sub_area_id: 1359,
        subarea_name: "Celebrity Sweets"
      },
      {
        hub_id: 4,
        sub_area_id: 1360,
        subarea_name: "CH Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1361,
        subarea_name: "Choma Village"
      },
      {
        hub_id: 4,
        sub_area_id: 1362,
        subarea_name: "Cosmos Executive Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1363,
        subarea_name: "D Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1364,
        subarea_name: "Dharam Colony"
      },
      {
        hub_id: 4,
        sub_area_id: 1365,
        subarea_name: "E Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1366,
        subarea_name: "F Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1367,
        subarea_name: "G Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1368,
        subarea_name: "H Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1369,
        subarea_name: "I Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1370,
        subarea_name: "J Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1371,
        subarea_name: "K Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1372,
        subarea_name: "Mahindra Aura"
      },
      {
        hub_id: 4,
        sub_area_id: 1373,
        subarea_name: "Main Road"
      },
      {
        hub_id: 4,
        sub_area_id: 1374,
        subarea_name: "Milan Vihar 1"
      },
      {
        hub_id: 4,
        sub_area_id: 1375,
        subarea_name: "New Palam Vihar Phase 1"
      },
      {
        hub_id: 4,
        sub_area_id: 1376,
        subarea_name: "Orchid Island"
      },
      {
        hub_id: 4,
        sub_area_id: 1377,
        subarea_name: "Palam Vihar (sector 2)"
      },
      {
        hub_id: 4,
        sub_area_id: 1378,
        subarea_name: "Park View City 2"
      },
      {
        hub_id: 4,
        sub_area_id: 1379,
        subarea_name: "Park View Residency"
      },
      {
        hub_id: 4,
        sub_area_id: 1380,
        subarea_name: "Sanjay Gram"
      }
    ],
    499: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2750,
        subarea_name: "Sector 3"
      }
    ],
    500: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2751,
        subarea_name: "Sector 3A"
      }
    ],
    501: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1381,
        subarea_name: "Ats Kocoon"
      },
      {
        hub_id: 4,
        sub_area_id: 1382,
        subarea_name: "Bestech Park View Residency"
      },
      {
        hub_id: 4,
        sub_area_id: 1383,
        subarea_name: "Cosmos Executive Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1384,
        subarea_name: "Diplomatic Green"
      },
      {
        hub_id: 4,
        sub_area_id: 1385,
        subarea_name: "Experion Windchants"
      },
      {
        hub_id: 4,
        sub_area_id: 1386,
        subarea_name: "Mahindra Aura"
      },
      {
        hub_id: 4,
        sub_area_id: 1387,
        subarea_name: "Park View Residency"
      },
      {
        hub_id: 4,
        sub_area_id: 1388,
        subarea_name: "Phase 1"
      },
      {
        hub_id: 4,
        sub_area_id: 1389,
        subarea_name: "Puri Diplomatic Green"
      },
      {
        hub_id: 4,
        sub_area_id: 2752,
        subarea_name: "New Palam Vihar (Sector 3)"
      }
    ],
    502: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1390,
        subarea_name: "Gurgaon Dreams Mall"
      },
      {
        hub_id: 4,
        sub_area_id: 1391,
        subarea_name: "Laxman Vihar"
      },
      {
        hub_id: 4,
        sub_area_id: 1392,
        subarea_name: "Laxman Vihar Phase I"
      },
      {
        hub_id: 4,
        sub_area_id: 1393,
        subarea_name: "New Colony"
      },
      {
        hub_id: 4,
        sub_area_id: 1394,
        subarea_name: "New Railway Road"
      },
      {
        hub_id: 4,
        sub_area_id: 1395,
        subarea_name: "Ritu Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1396,
        subarea_name: "Urban Estate"
      },
      {
        hub_id: 4,
        sub_area_id: 2753,
        subarea_name: "Sector 4"
      }
    ],
    503: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1397,
        subarea_name: "Ashok Vihar Phase 3"
      },
      {
        hub_id: 4,
        sub_area_id: 1398,
        subarea_name: "CGHS Dispensary"
      },
      {
        hub_id: 4,
        sub_area_id: 1399,
        subarea_name: "Part 6"
      },
      {
        hub_id: 4,
        sub_area_id: 2754,
        subarea_name: "Sector 5"
      }
    ],
    504: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2755,
        subarea_name: "Sector 6"
      }
    ],
    505: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2756,
        subarea_name: "Sector 6A"
      }
    ],
    506: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1400,
        subarea_name: "Arjun Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 1401,
        subarea_name: "Dayal Market"
      },
      {
        hub_id: 4,
        sub_area_id: 1402,
        subarea_name: "EW Colony"
      },
      {
        hub_id: 4,
        sub_area_id: 1403,
        subarea_name: "Housing Board Colony"
      },
      {
        hub_id: 4,
        sub_area_id: 1404,
        subarea_name: "Jyoti Park"
      },
      {
        hub_id: 4,
        sub_area_id: 1405,
        subarea_name: "New Colony"
      },
      {
        hub_id: 4,
        sub_area_id: 1406,
        subarea_name: "Other Society"
      },
      {
        hub_id: 4,
        sub_area_id: 1407,
        subarea_name: "Shivpuri"
      },
      {
        hub_id: 4,
        sub_area_id: 1408,
        subarea_name: "Urban Estate"
      },
      {
        hub_id: 4,
        sub_area_id: 1409,
        subarea_name: "Vasant Marg"
      },
      {
        hub_id: 4,
        sub_area_id: 2757,
        subarea_name: "Sector 7"
      }
    ],
    507: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2758,
        subarea_name: "Sector 8"
      }
    ],
    508: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1410,
        subarea_name: "Gali No 12"
      },
      {
        hub_id: 4,
        sub_area_id: 1411,
        subarea_name: "New CRPF Housing Society"
      },
      {
        hub_id: 4,
        sub_area_id: 1412,
        subarea_name: "Plot No 7"
      },
      {
        hub_id: 4,
        sub_area_id: 2759,
        subarea_name: "Sector 9"
      }
    ],
    509: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1413,
        subarea_name: "Chattarpur"
      },
      {
        hub_id: 4,
        sub_area_id: 1414,
        subarea_name: "Dronkunj CGHS Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1415,
        subarea_name: "Jammu & Kashmir Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1416,
        subarea_name: "Viman Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 2760,
        subarea_name: "Sector 9A"
      },
      {
        hub_id: 4,
        sub_area_id: 3627,
        subarea_name: "RPF Suraksha Apartment"
      }
    ],
    510: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2761,
        subarea_name: "Sector 9B"
      }
    ],
    511: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1417,
        subarea_name: "Housing Board Colony"
      }
    ],
    512: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1422,
        subarea_name: "Jacob Pura"
      },
      {
        hub_id: 4,
        sub_area_id: 2763,
        subarea_name: "Sector 12A"
      }
    ],
    513: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2764,
        subarea_name: "Sector 13"
      }
    ],
    514: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1423,
        subarea_name: "Old DLF Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1424,
        subarea_name: "Sanjay Gram"
      },
      {
        hub_id: 3,
        sub_area_id: 2765,
        subarea_name: "Sector 14"
      }
    ],
    515: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1425,
        subarea_name: "Civil Lines"
      },
      {
        hub_id: 4,
        sub_area_id: 1426,
        subarea_name: "Hewo Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1427,
        subarea_name: "Hope Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1428,
        subarea_name: "Housing Board Colony"
      },
      {
        hub_id: 4,
        sub_area_id: 1429,
        subarea_name: "Part 1"
      },
      {
        hub_id: 4,
        sub_area_id: 1430,
        subarea_name: "Part 2"
      },
      {
        hub_id: 4,
        sub_area_id: 1431,
        subarea_name: "Patel Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 1432,
        subarea_name: "Premier Urban Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1433,
        subarea_name: "Rail Vihar"
      },
      {
        hub_id: 4,
        sub_area_id: 2766,
        subarea_name: "Sector 15"
      }
    ],
    516: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2767,
        subarea_name: "Sector 16"
      }
    ],
    517: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1434,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1435,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1436,
        subarea_name: "Defence Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1437,
        subarea_name: "Iffco Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1438,
        subarea_name: "Indian Bank Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1439,
        subarea_name: "Mdi Campus"
      },
      {
        hub_id: 3,
        sub_area_id: 1440,
        subarea_name: "Sukh Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 1441,
        subarea_name: "U Block"
      },
      {
        hub_id: 3,
        sub_area_id: 2768,
        subarea_name: "Sector 17"
      }
    ],
    518: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1442,
        subarea_name: "Institute Of Food Security"
      },
      {
        hub_id: 3,
        sub_area_id: 2769,
        subarea_name: "Sector 18"
      }
    ],
    519: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2770,
        subarea_name: "Sector 19"
      }
    ],
    520: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2771,
        subarea_name: "Sector 20"
      }
    ],
    521: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1443,
        subarea_name: "E Pocket"
      },
      {
        hub_id: 4,
        sub_area_id: 1444,
        subarea_name: "Pocket A"
      },
      {
        hub_id: 4,
        sub_area_id: 1445,
        subarea_name: "Pocket C"
      },
      {
        hub_id: 4,
        sub_area_id: 1446,
        subarea_name: "Pocket E"
      },
      {
        hub_id: 4,
        sub_area_id: 1447,
        subarea_name: "Sushant Lok 2"
      },
      {
        hub_id: 4,
        sub_area_id: 1448,
        subarea_name: "Surya Vihar"
      },
      {
        hub_id: 4,
        sub_area_id: 2772,
        subarea_name: "Sector 21"
      }
    ],
    522: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1449,
        subarea_name: "Block 22A"
      },
      {
        hub_id: 4,
        sub_area_id: 1450,
        subarea_name: "Block 22B"
      },
      {
        hub_id: 4,
        sub_area_id: 1451,
        subarea_name: "Gurgaon One Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 2773,
        subarea_name: "Sector 22"
      }
    ],
    523: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1452,
        subarea_name: "C Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1453,
        subarea_name: "East Pocket"
      },
      {
        hub_id: 4,
        sub_area_id: 1454,
        subarea_name: "East Pocket Gate Number 1"
      },
      {
        hub_id: 4,
        sub_area_id: 1455,
        subarea_name: "I Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1456,
        subarea_name: "J Block"
      },
      {
        hub_id: 4,
        sub_area_id: 1457,
        subarea_name: "Market Road"
      },
      {
        hub_id: 4,
        sub_area_id: 1458,
        subarea_name: "Nirbhaya Society"
      },
      {
        hub_id: 4,
        sub_area_id: 1459,
        subarea_name: "Pocket E"
      },
      {
        hub_id: 4,
        sub_area_id: 2774,
        subarea_name: "Sector 23"
      }
    ],
    524: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2775,
        subarea_name: "Sector 23A"
      }
    ],
    525: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1460,
        subarea_name: "Lagune Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1461,
        subarea_name: "Saraswati Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1462,
        subarea_name: "Shankar Chowk"
      },
      {
        hub_id: 3,
        sub_area_id: 2776,
        subarea_name: "Sector 24"
      }
    ],
    526: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2777,
        subarea_name: "Sector 25"
      }
    ],
    527: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2778,
        subarea_name: "Sector 26"
      }
    ],
    528: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2779,
        subarea_name: "Sector 26A"
      }
    ],
    529: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2780,
        subarea_name: "Sector 27"
      }
    ],
    530: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1463,
        subarea_name: "Chakkarpur"
      },
      {
        hub_id: 3,
        sub_area_id: 1464,
        subarea_name: "Hermitage"
      },
      {
        hub_id: 3,
        sub_area_id: 1465,
        subarea_name: "Jan Pratinidhi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1466,
        subarea_name: "The Laburnum"
      },
      {
        hub_id: 3,
        sub_area_id: 1467,
        subarea_name: "Manju Market"
      },
      {
        hub_id: 3,
        sub_area_id: 1468,
        subarea_name: "Maruti Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1469,
        subarea_name: "Saraswati Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 2781,
        subarea_name: "Sector 28"
      }
    ],
    531: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2782,
        subarea_name: "Sector 29"
      }
    ],
    532: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1470,
        subarea_name: "DLF Star Tower"
      },
      {
        hub_id: 3,
        sub_area_id: 1471,
        subarea_name: "J Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1472,
        subarea_name: "Jalvayu Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1473,
        subarea_name: "M Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1474,
        subarea_name: "Raheja Atlantis"
      },
      {
        hub_id: 3,
        sub_area_id: 1475,
        subarea_name: "Uniworld City"
      },
      {
        hub_id: 3,
        sub_area_id: 1476,
        subarea_name: "Uniworld City 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1477,
        subarea_name: "Uniworld City East"
      },
      {
        hub_id: 3,
        sub_area_id: 1478,
        subarea_name: "Uniworld City West"
      },
      {
        hub_id: 3,
        sub_area_id: 1479,
        subarea_name: "World Spa East"
      },
      {
        hub_id: 3,
        sub_area_id: 1480,
        subarea_name: "World Spa West"
      },
      {
        hub_id: 3,
        sub_area_id: 2783,
        subarea_name: "Sector 30"
      }
    ],
    533: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1481,
        subarea_name: "Community Center"
      },
      {
        hub_id: 3,
        sub_area_id: 1482,
        subarea_name: "Hewo Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1483,
        subarea_name: "Housing Board Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1484,
        subarea_name: "M Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1485,
        subarea_name: "Raheja Atlantis"
      },
      {
        hub_id: 3,
        sub_area_id: 1486,
        subarea_name: "Raheja Teachers Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1487,
        subarea_name: "Uniworld City"
      },
      {
        hub_id: 3,
        sub_area_id: 2784,
        subarea_name: "Sector 31"
      }
    ],
    534: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2785,
        subarea_name: "Sector 32"
      }
    ],
    535: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1488,
        subarea_name: "A2 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1489,
        subarea_name: "Hans Enclave"
      },
      {
        hub_id: 3,
        sub_area_id: 1490,
        subarea_name: "Jmd Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1491,
        subarea_name: "The Residences Uniworld Resorts"
      },
      {
        hub_id: 3,
        sub_area_id: 1492,
        subarea_name: "The Villas"
      },
      {
        hub_id: 3,
        sub_area_id: 1493,
        subarea_name: "Unitech Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 2786,
        subarea_name: "Sector 33"
      },
      {
        hub_id: 3,
        sub_area_id: 3009,
        subarea_name: "Rajiv Colony"
      }
    ],
    536: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2787,
        subarea_name: "Sector 34"
      }
    ],
    537: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2788,
        subarea_name: "Sector 35"
      }
    ],
    538: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2789,
        subarea_name: "Sector 36"
      }
    ],
    539: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2790,
        subarea_name: "Sector 37"
      }
    ],
    540: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 2791,
        subarea_name: "Sector 37A"
      }
    ],
    541: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1494,
        subarea_name: "Vatika"
      },
      {
        hub_id: 4,
        sub_area_id: 2792,
        subarea_name: "Sector 37B"
      }
    ],
    542: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1495,
        subarea_name: "Taksila Heights"
      },
      {
        hub_id: 4,
        sub_area_id: 2793,
        subarea_name: "Sector 37C"
      },
      {
        hub_id: 4,
        sub_area_id: 3007,
        subarea_name: "Corona Octopus"
      }
    ],
    543: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 4,
        sub_area_id: 1496,
        subarea_name: "Bptp Park Serene"
      },
      {
        hub_id: 4,
        sub_area_id: 1497,
        subarea_name: "Parsvnath Green Ville"
      },
      {
        hub_id: 4,
        sub_area_id: 1498,
        subarea_name: "Rampratha City"
      },
      {
        hub_id: 4,
        sub_area_id: 2794,
        subarea_name: "Sector 37D"
      }
    ],
    544: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1499,
        subarea_name: "B Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1500,
        subarea_name: "Herval Green"
      },
      {
        hub_id: 3,
        sub_area_id: 2795,
        subarea_name: "Sector 38"
      }
    ],
    545: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1501,
        subarea_name: "City Height Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1502,
        subarea_name: "Housing Board Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1503,
        subarea_name: "Jharsa Village"
      },
      {
        hub_id: 3,
        sub_area_id: 1504,
        subarea_name: "K Block"
      },
      {
        hub_id: 3,
        sub_area_id: 2796,
        subarea_name: "Sector 39"
      }
    ],
    546: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1505,
        subarea_name: "Areban Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1506,
        subarea_name: "Ashok Building"
      },
      {
        hub_id: 3,
        sub_area_id: 1507,
        subarea_name: "Greenwood City"
      },
      {
        hub_id: 3,
        sub_area_id: 1508,
        subarea_name: "Matrichhaya"
      },
      {
        hub_id: 3,
        sub_area_id: 1509,
        subarea_name: "Mohiyal Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1510,
        subarea_name: "Superia Homes"
      },
      {
        hub_id: 3,
        sub_area_id: 2797,
        subarea_name: "Sector 40"
      }
    ],
    547: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 2798,
        subarea_name: "Sector 41"
      }
    ],
    548: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1511,
        subarea_name: "Central Park 1"
      },
      {
        hub_id: 3,
        sub_area_id: 2799,
        subarea_name: "Sector 42"
      }
    ],
    549: [
      {
        hub_id: "",
        sub_area_id: "",
        subarea_name: "Select Subarea"
      },
      {
        hub_id: 3,
        sub_area_id: 1512,
        subarea_name: "Agrim Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1513,
        subarea_name: "Air India Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1514,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1515,
        subarea_name: "Cameron Court"
      },
      {
        hub_id: 3,
        sub_area_id: 1516,
        subarea_name: "Connoisseur Twin Towers"
      },
      {
        hub_id: 3,
        sub_area_id: 1517,
        subarea_name: "DLF Pinnacle"
      },
      {
        hub_id: 3,
        sub_area_id: 1518,
        subarea_name: "Galaxy Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1519,
        subarea_name: "Hamlin Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1520,
        subarea_name: "Hextax Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1521,
        subarea_name: "Huda Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1522,
        subarea_name: "Jeevan Tara Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1523,
        subarea_name: "K Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1524,
        subarea_name: "Mahalakshmi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1525,
        subarea_name: "Maple Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1526,
        subarea_name: "Navyug Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1527,
        subarea_name: "Olive Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1528,
        subarea_name: "Powergrid Township"
      },
      {
        hub_id: 3,
        sub_area_id: 1529,
        subarea_name: "Pwo Housing Complex Sector 43"
      },
      {
        hub_id: 3,
        sub_area_id: 1530,
        subarea_name: "Pwo Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1531,
        subarea_name: "Rama Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1532,
        subarea_name: "Sanskriti Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1533,
        subarea_name: "Sujjan Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1534,
        subarea_name: "Surbhi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1535,
        subarea_name: "Surya Bancourt Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1536,
        subarea_name: "Sushant Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1538,
        subarea_name: "Tarika Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 2800,
        subarea_name: "Sector 43"
      },
      {
        hub_id: 3,
        sub_area_id: 3024,
        subarea_name: "Lord Krishna Apartment"
      }
    ],
    550: [
      {
        hub_id: 3,
        sub_area_id: 1539,
        subarea_name: "Institutional Area"
      },
      {
        hub_id: 3,
        sub_area_id: 2801,
        subarea_name: "Sector 44"
      }
    ],
    551: [
      {
        hub_id: 3,
        sub_area_id: 1540,
        subarea_name: "Apex Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1541,
        subarea_name: "Ayaachi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1542,
        subarea_name: "Greenwood City"
      },
      {
        hub_id: 3,
        sub_area_id: 1543,
        subarea_name: "Greenwood's City"
      },
      {
        hub_id: 3,
        sub_area_id: 1544,
        subarea_name: "Huda Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1545,
        subarea_name: "Jai Shree Ganesh Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1546,
        subarea_name: "Jasminium Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1547,
        subarea_name: "Royal Residency Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1548,
        subarea_name: "San Marino Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1549,
        subarea_name: "Shri Ganesh Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 2802,
        subarea_name: "Sector 45"
      }
    ],
    552: [
      {
        hub_id: 3,
        sub_area_id: 1550,
        subarea_name: "Bulider Floor"
      },
      {
        hub_id: 3,
        sub_area_id: 1551,
        subarea_name: "Greenwood City"
      },
      {
        hub_id: 3,
        sub_area_id: 1552,
        subarea_name: "Huda Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1553,
        subarea_name: "JVTS Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1554,
        subarea_name: "Unitech Residency Greens"
      },
      {
        hub_id: 3,
        sub_area_id: 2803,
        subarea_name: "Sector 46"
      }
    ],
    553: [
      {
        hub_id: 3,
        sub_area_id: 1555,
        subarea_name: "Airport Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1556,
        subarea_name: "Avalon Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1557,
        subarea_name: "Bestech Park View Spa"
      },
      {
        hub_id: 3,
        sub_area_id: 1558,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1559,
        subarea_name: "Dew Drops Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1560,
        subarea_name: "Fateh Nagar"
      },
      {
        hub_id: 3,
        sub_area_id: 1561,
        subarea_name: "Kunj Bihari CGHS"
      },
      {
        hub_id: 3,
        sub_area_id: 1562,
        subarea_name: "M2k Aura Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1563,
        subarea_name: "Malibu Town"
      },
      {
        hub_id: 3,
        sub_area_id: 1564,
        subarea_name: "Media House Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1565,
        subarea_name: "Millenium Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1566,
        subarea_name: "Park View Spa"
      },
      {
        hub_id: 3,
        sub_area_id: 1567,
        subarea_name: "Pragati Hills Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1568,
        subarea_name: "Rail Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1569,
        subarea_name: "Sohna Road"
      },
      {
        hub_id: 3,
        sub_area_id: 1570,
        subarea_name: "Tarun Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1571,
        subarea_name: "The Palladians"
      },
      {
        hub_id: 3,
        sub_area_id: 1572,
        subarea_name: "Today's Blossom 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1573,
        subarea_name: "Today's Blossoms 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1574,
        subarea_name: "Uniworld Garden 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1575,
        subarea_name: "Uniworld Garden 2"
      },
      {
        hub_id: 3,
        sub_area_id: 2804,
        subarea_name: "Sector 47"
      }
    ],
    554: [
      {
        hub_id: 3,
        sub_area_id: 1576,
        subarea_name: "Aaron Ville"
      },
      {
        hub_id: 3,
        sub_area_id: 1577,
        subarea_name: "Bestech Park View City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1578,
        subarea_name: "Central Park 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1579,
        subarea_name: "Central Park Resorts"
      },
      {
        hub_id: 3,
        sub_area_id: 1580,
        subarea_name: "Eldeco Mansions"
      },
      {
        hub_id: 3,
        sub_area_id: 1581,
        subarea_name: "Parasvnath Greenville"
      },
      {
        hub_id: 3,
        sub_area_id: 1582,
        subarea_name: "Park View City"
      },
      {
        hub_id: 3,
        sub_area_id: 1583,
        subarea_name: "Park View City 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1584,
        subarea_name: "Park View City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1585,
        subarea_name: "Parsvnath Green Ville"
      },
      {
        hub_id: 3,
        sub_area_id: 1586,
        subarea_name: "Root Courtyard"
      },
      {
        hub_id: 3,
        sub_area_id: 1587,
        subarea_name: "Roots Courtyard"
      },
      {
        hub_id: 3,
        sub_area_id: 1588,
        subarea_name: "Shispal Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1589,
        subarea_name: "Tatvam Villa"
      },
      {
        hub_id: 3,
        sub_area_id: 1590,
        subarea_name: "Vipul Green"
      },
      {
        hub_id: 3,
        sub_area_id: 1591,
        subarea_name: "Vipul World"
      },
      {
        hub_id: 3,
        sub_area_id: 2805,
        subarea_name: "Sector 48"
      }
    ],
    555: [
      {
        hub_id: 3,
        sub_area_id: 1592,
        subarea_name: "Bestech Park View City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1593,
        subarea_name: "Emilia 2, Vatika City"
      },
      {
        hub_id: 3,
        sub_area_id: 1594,
        subarea_name: "Lilac 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1595,
        subarea_name: "Omaxe The Nile"
      },
      {
        hub_id: 3,
        sub_area_id: 1596,
        subarea_name: "Orchid Petal"
      },
      {
        hub_id: 3,
        sub_area_id: 1597,
        subarea_name: "Park View City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1598,
        subarea_name: "Rosewood City"
      },
      {
        hub_id: 3,
        sub_area_id: 1599,
        subarea_name: "Shispal Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1600,
        subarea_name: "Sishpal Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1601,
        subarea_name: "South City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1602,
        subarea_name: "Southend Floors"
      },
      {
        hub_id: 3,
        sub_area_id: 1603,
        subarea_name: "The Lilac 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1604,
        subarea_name: "Uppal Southend"
      },
      {
        hub_id: 3,
        sub_area_id: 1605,
        subarea_name: "Vatika City"
      },
      {
        hub_id: 3,
        sub_area_id: 1606,
        subarea_name: "Wembley Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 2806,
        subarea_name: "Sector 49"
      }
    ],
    556: [
      {
        hub_id: 3,
        sub_area_id: 1607,
        subarea_name: "The Close South"
      },
      {
        hub_id: 3,
        sub_area_id: 1608,
        subarea_name: "Deerwood Chase"
      },
      {
        hub_id: 3,
        sub_area_id: 1609,
        subarea_name: "Mayfield Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1610,
        subarea_name: "The Close North"
      },
      {
        hub_id: 3,
        sub_area_id: 1611,
        subarea_name: "Rosewood City"
      },
      {
        hub_id: 3,
        sub_area_id: 1612,
        subarea_name: "The Hibiscus"
      },
      {
        hub_id: 3,
        sub_area_id: 1613,
        subarea_name: "Unitech Espace"
      },
      {
        hub_id: 3,
        sub_area_id: 1614,
        subarea_name: "Unitech Fresco"
      },
      {
        hub_id: 3,
        sub_area_id: 1615,
        subarea_name: "Unitech Harmony"
      },
      {
        hub_id: 3,
        sub_area_id: 2807,
        subarea_name: "Sector 50"
      }
    ],
    557: [
      {
        hub_id: 3,
        sub_area_id: 1616,
        subarea_name: "Abhinandan Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1617,
        subarea_name: "Arzoo Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1618,
        subarea_name: "Dass Aims"
      },
      {
        hub_id: 3,
        sub_area_id: 1619,
        subarea_name: "M2k Symphony"
      },
      {
        hub_id: 3,
        sub_area_id: 1620,
        subarea_name: "Mapple Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1621,
        subarea_name: "Mayfield Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1622,
        subarea_name: "New Shivalik Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1623,
        subarea_name: "Orchid Island"
      },
      {
        hub_id: 3,
        sub_area_id: 1624,
        subarea_name: "Princeton Floors"
      },
      {
        hub_id: 3,
        sub_area_id: 1625,
        subarea_name: "Shiv Shankar Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1626,
        subarea_name: "Shivalik Apartments"
      },
      {
        hub_id: 3,
        sub_area_id: 1627,
        subarea_name: "The Citizen"
      },
      {
        hub_id: 3,
        sub_area_id: 1628,
        subarea_name: "Today's Blossoms 2"
      },
      {
        hub_id: 3,
        sub_area_id: 2808,
        subarea_name: "Sector 51"
      }
    ],
    558: [
      {
        hub_id: 3,
        sub_area_id: 1629,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1630,
        subarea_name: "Adhunik Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1631,
        subarea_name: "Ansal Sushant Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1632,
        subarea_name: "Ardee City"
      },
      {
        hub_id: 3,
        sub_area_id: 1633,
        subarea_name: "Emmar Emerald Green"
      },
      {
        hub_id: 3,
        sub_area_id: 1634,
        subarea_name: "Good Luck Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1635,
        subarea_name: "Green Homes"
      },
      {
        hub_id: 3,
        sub_area_id: 1636,
        subarea_name: "Jai Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1637,
        subarea_name: "Karan Veer Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1638,
        subarea_name: "Palm Grove Ardee City"
      },
      {
        hub_id: 3,
        sub_area_id: 1639,
        subarea_name: "Prithvi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1640,
        subarea_name: "Residency Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1641,
        subarea_name: "Shakti Skylark Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1642,
        subarea_name: "Sushant Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1643,
        subarea_name: "The Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 2809,
        subarea_name: "Sector 52"
      }
    ],
    559: [
      {
        hub_id: 3,
        sub_area_id: 1644,
        subarea_name: "Ardee City"
      },
      {
        hub_id: 3,
        sub_area_id: 2810,
        subarea_name: "Sector 52A"
      }
    ],
    560: [
      {
        hub_id: 3,
        sub_area_id: 1646,
        subarea_name: "Ourania Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1647,
        subarea_name: "Parsvnath Exotica"
      },
      {
        hub_id: 3,
        sub_area_id: 1648,
        subarea_name: "Silver Oaks"
      },
      {
        hub_id: 3,
        sub_area_id: 1649,
        subarea_name: "The Palm Springs"
      },
      {
        hub_id: 3,
        sub_area_id: 1650,
        subarea_name: "Vipul Belmonte"
      },
      {
        hub_id: 3,
        sub_area_id: 2811,
        subarea_name: "Sector 53"
      }
    ],
    561: [
      {
        hub_id: 3,
        sub_area_id: 1651,
        subarea_name: "Aravali Homes"
      },
      {
        hub_id: 3,
        sub_area_id: 1652,
        subarea_name: "B2 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1653,
        subarea_name: "Bhawani Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1654,
        subarea_name: "Cedar Estate Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1655,
        subarea_name: "DLF Park Place"
      },
      {
        hub_id: 3,
        sub_area_id: 1656,
        subarea_name: "Golf Link Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1657,
        subarea_name: "Ridgeview IFS Apartments"
      },
      {
        hub_id: 3,
        sub_area_id: 1658,
        subarea_name: "Krishna Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1659,
        subarea_name: "Lagune Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1660,
        subarea_name: "New Sathi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1661,
        subarea_name: "Palm Springs Tower A"
      },
      {
        hub_id: 3,
        sub_area_id: 1662,
        subarea_name: "Parkplace Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1663,
        subarea_name: "Ridgeview IFS Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1664,
        subarea_name: "Shiv Shakti Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1665,
        subarea_name: "Suncity Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1666,
        subarea_name: "Suncity Orchid Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1667,
        subarea_name: "Vipul Belmonte"
      },
      {
        hub_id: 3,
        sub_area_id: 2812,
        subarea_name: "Sector 54"
      }
    ],
    562: [
      {
        hub_id: 3,
        sub_area_id: 1668,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1669,
        subarea_name: "Adarsh Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1670,
        subarea_name: "Brother Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1671,
        subarea_name: "Chandra CGHS Shri Kirti Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1672,
        subarea_name: "City Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1673,
        subarea_name: "Cloud Homes"
      },
      {
        hub_id: 3,
        sub_area_id: 1674,
        subarea_name: "Highland Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1675,
        subarea_name: "Karam Hi Dharam Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1676,
        subarea_name: "Oasis Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1677,
        subarea_name: "Parth Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1678,
        subarea_name: "Sagavi Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1679,
        subarea_name: "Sushank Lok 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1680,
        subarea_name: "Vastu Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 2813,
        subarea_name: "Sector 55"
      }
    ],
    563: [
      {
        hub_id: 3,
        sub_area_id: 1681,
        subarea_name: "Akashganga Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1682,
        subarea_name: "Alaknanda Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1683,
        subarea_name: "Alankar Aparments"
      },
      {
        hub_id: 3,
        sub_area_id: 1684,
        subarea_name: "Anand Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1685,
        subarea_name: "Arihant Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1686,
        subarea_name: "Ashoka"
      },
      {
        hub_id: 3,
        sub_area_id: 1687,
        subarea_name: "Davender Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1688,
        subarea_name: "Divya Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1689,
        subarea_name: "Gail Housing Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1690,
        subarea_name: "Ganpati Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1691,
        subarea_name: "Global Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1692,
        subarea_name: "Gracious Tower Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1693,
        subarea_name: "Hewo 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1694,
        subarea_name: "Hewo 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1695,
        subarea_name: "Huda Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1696,
        subarea_name: "Jalvayu Towers"
      },
      {
        hub_id: 3,
        sub_area_id: 1697,
        subarea_name: "Jawahar Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1698,
        subarea_name: "Joy Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1699,
        subarea_name: "Joy Kunj Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1700,
        subarea_name: "Kanchanjunga Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1701,
        subarea_name: "Kanchanjunga Tower"
      },
      {
        hub_id: 3,
        sub_area_id: 1702,
        subarea_name: "Karan Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 1703,
        subarea_name: "Kendriya Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1704,
        subarea_name: "Lions Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1705,
        subarea_name: "Madhur Jeevan"
      },
      {
        hub_id: 3,
        sub_area_id: 1706,
        subarea_name: "Maitry RBI Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1707,
        subarea_name: "Mariners Home"
      },
      {
        hub_id: 3,
        sub_area_id: 1708,
        subarea_name: "Old Hewo Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1709,
        subarea_name: "Olive Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1710,
        subarea_name: "Park Royal Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1711,
        subarea_name: "Prerana Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1712,
        subarea_name: "Rail Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1713,
        subarea_name: "Royal Palm Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1714,
        subarea_name: "Sagar Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1715,
        subarea_name: "Shivalaya Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1716,
        subarea_name: "Smriti Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1717,
        subarea_name: "Sukh Shanti Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1718,
        subarea_name: "Sukriti Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1719,
        subarea_name: "Technical Paradise"
      },
      {
        hub_id: 3,
        sub_area_id: 1720,
        subarea_name: "Technocrat Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1721,
        subarea_name: "Vigyan Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 2814,
        subarea_name: "Sector 56"
      }
    ],
    564: [
      {
        hub_id: 3,
        sub_area_id: 1722,
        subarea_name: "Aloha Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1723,
        subarea_name: "BPTP Freedom Park Life"
      },
      {
        hub_id: 3,
        sub_area_id: 1724,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1725,
        subarea_name: "F Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1726,
        subarea_name: "Florence Marvel Villa"
      },
      {
        hub_id: 3,
        sub_area_id: 1727,
        subarea_name: "G Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1728,
        subarea_name: "H Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1729,
        subarea_name: "Harmony Homes Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1730,
        subarea_name: "Huda Colony"
      },
      {
        hub_id: 3,
        sub_area_id: 1731,
        subarea_name: "I Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1732,
        subarea_name: "Legend Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1733,
        subarea_name: "M2k White House"
      },
      {
        hub_id: 3,
        sub_area_id: 1734,
        subarea_name: "Oriental Homes"
      },
      {
        hub_id: 3,
        sub_area_id: 1735,
        subarea_name: "Rail Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1736,
        subarea_name: "Sushank Lok 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1737,
        subarea_name: "Sushank Lok 3"
      },
      {
        hub_id: 3,
        sub_area_id: 1738,
        subarea_name: "Sushant Lok 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1739,
        subarea_name: "The Hibiscus"
      },
      {
        hub_id: 3,
        sub_area_id: 2815,
        subarea_name: "Sector 57"
      }
    ],
    565: [
      {
        hub_id: 3,
        sub_area_id: 1740,
        subarea_name: "Ireo The Grand Arch"
      },
      {
        hub_id: 3,
        sub_area_id: 1741,
        subarea_name: "The Residency, Ardee City"
      },
      {
        hub_id: 3,
        sub_area_id: 2816,
        subarea_name: "Sector 58"
      }
    ],
    566: [
      {
        hub_id: 3,
        sub_area_id: 1742,
        subarea_name: "Tata Housing Raisina Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 1743,
        subarea_name: "Tata Raisina Residency, Victoria Tower"
      },
      {
        hub_id: 3,
        sub_area_id: 2817,
        subarea_name: "Sector 59"
      }
    ],
    567: [
      {
        hub_id: 3,
        sub_area_id: 2818,
        subarea_name: "Sector 60"
      },
      {
        hub_id: 3,
        sub_area_id: 3082,
        subarea_name: "Ireo skyon"
      }
    ],
    568: [
      {
        hub_id: 3,
        sub_area_id: 1744,
        subarea_name: "Arzoo Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1745,
        subarea_name: "Emaar Emerald Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1746,
        subarea_name: "Olive Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1747,
        subarea_name: "Pioneer Park"
      },
      {
        hub_id: 3,
        sub_area_id: 1748,
        subarea_name: "Pioneer Presidia"
      },
      {
        hub_id: 3,
        sub_area_id: 2819,
        subarea_name: "Sector 61"
      }
    ],
    569: [
      {
        hub_id: 3,
        sub_area_id: 2820,
        subarea_name: "Sector 62"
      }
    ],
    570: [
      {
        hub_id: 3,
        sub_area_id: 2821,
        subarea_name: "Sector 63"
      }
    ],
    571: [
      {
        hub_id: 4,
        sub_area_id: 2822,
        subarea_name: "Sector 64"
      }
    ],
    572: [
      {
        hub_id: 3,
        sub_area_id: 1749,
        subarea_name: "Emaar Emerald Hills"
      },
      {
        hub_id: 3,
        sub_area_id: 1750,
        subarea_name: "Emaar Palm Drive"
      },
      {
        hub_id: 3,
        sub_area_id: 1751,
        subarea_name: "Emerald Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1752,
        subarea_name: "Golf Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1753,
        subarea_name: "M3m Merlin"
      },
      {
        hub_id: 3,
        sub_area_id: 2823,
        subarea_name: "Sector 65"
      }
    ],
    573: [
      {
        hub_id: 3,
        sub_area_id: 1754,
        subarea_name: "Bestech Park View Spa Next"
      },
      {
        hub_id: 3,
        sub_area_id: 1755,
        subarea_name: "Bptp Park Prime"
      },
      {
        hub_id: 3,
        sub_area_id: 1756,
        subarea_name: "Emaar Emerald Hills"
      },
      {
        hub_id: 3,
        sub_area_id: 1757,
        subarea_name: "Emaar Palm Drive"
      },
      {
        hub_id: 3,
        sub_area_id: 1758,
        subarea_name: "Emaar Palmdrive"
      },
      {
        hub_id: 3,
        sub_area_id: 1759,
        subarea_name: "Ireo Uptown"
      },
      {
        hub_id: 3,
        sub_area_id: 1760,
        subarea_name: "M3m Merlin"
      },
      {
        hub_id: 3,
        sub_area_id: 2824,
        subarea_name: "Sector 66"
      }
    ],
    574: [
      {
        hub_id: 3,
        sub_area_id: 1761,
        subarea_name: "Ansal Esencia Society Road"
      },
      {
        hub_id: 3,
        sub_area_id: 1762,
        subarea_name: "Bestech Park View Spa Next"
      },
      {
        hub_id: 3,
        sub_area_id: 1763,
        subarea_name: "Ireo Victory Valley"
      },
      {
        hub_id: 3,
        sub_area_id: 1764,
        subarea_name: "Keshav Nigam"
      },
      {
        hub_id: 3,
        sub_area_id: 1765,
        subarea_name: "M3m Merlin"
      },
      {
        hub_id: 3,
        sub_area_id: 2825,
        subarea_name: "Sector 67"
      },
      {
        hub_id: 3,
        sub_area_id: 3660,
        subarea_name: "Ansal Versalia"
      }
    ],
    575: [
      {
        hub_id: 3,
        sub_area_id: 2826,
        subarea_name: "Sector 68"
      }
    ],
    576: [
      {
        hub_id: 3,
        sub_area_id: 1766,
        subarea_name: "Tulip Orange"
      },
      {
        hub_id: 3,
        sub_area_id: 1767,
        subarea_name: "Tulip Purple"
      },
      {
        hub_id: 3,
        sub_area_id: 1768,
        subarea_name: "Tulip Violet"
      },
      {
        hub_id: 3,
        sub_area_id: 1769,
        subarea_name: "Tulip White Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 2827,
        subarea_name: "Sector 69"
      }
    ],
    577: [
      {
        hub_id: 3,
        sub_area_id: 1770,
        subarea_name: "Chd Avenue"
      },
      {
        hub_id: 3,
        sub_area_id: 1771,
        subarea_name: "GPL Eden Hights"
      },
      {
        hub_id: 3,
        sub_area_id: 1772,
        subarea_name: "M3M Escala"
      },
      {
        hub_id: 3,
        sub_area_id: 1773,
        subarea_name: "Paras Ireine Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1774,
        subarea_name: "Tata Primanti"
      },
      {
        hub_id: 3,
        sub_area_id: 1775,
        subarea_name: "Tata Primanti Society"
      },
      {
        hub_id: 3,
        sub_area_id: 2828,
        subarea_name: "Sector 70"
      }
    ],
    578: [
      {
        hub_id: 3,
        sub_area_id: 1776,
        subarea_name: "Paras Ireine Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 2829,
        subarea_name: "Sector 70A"
      }
    ],
    579: [
      {
        hub_id: 3,
        sub_area_id: 1777,
        subarea_name: "Abhinandan Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1778,
        subarea_name: "CHD Avenue"
      },
      {
        hub_id: 3,
        sub_area_id: 1779,
        subarea_name: "Pan Oasis"
      },
      {
        hub_id: 3,
        sub_area_id: 1781,
        subarea_name: "Tata Primanti"
      },
      {
        hub_id: 3,
        sub_area_id: 2830,
        subarea_name: "Spaze Prive"
      },
      {
        hub_id: 3,
        sub_area_id: 3600,
        subarea_name: "Andour heights"
      }
    ],
    580: [
      {
        hub_id: 3,
        sub_area_id: 2831,
        subarea_name: "Sector 72"
      },
      {
        hub_id: 3,
        sub_area_id: 3021,
        subarea_name: "Spaze Prive"
      },
      {
        hub_id: 3,
        sub_area_id: 3023,
        subarea_name: "Spaze Privi"
      },
      {
        hub_id: 3,
        sub_area_id: 3195,
        subarea_name: "Tata Primanti"
      }
    ],
    581: [
      {
        hub_id: 4,
        sub_area_id: 2832,
        subarea_name: "Sector 72A"
      }
    ],
    582: [
      {
        hub_id: 4,
        sub_area_id: 2833,
        subarea_name: "Sector 73"
      }
    ],
    583: [
      {
        hub_id: 4,
        sub_area_id: 2834,
        subarea_name: "Sector 74"
      }
    ],
    584: [
      {
        hub_id: 4,
        sub_area_id: 2835,
        subarea_name: "Sector 74A"
      }
    ],
    585: [
      {
        hub_id: 4,
        sub_area_id: 2836,
        subarea_name: "Sector 75"
      }
    ],
    586: [
      {
        hub_id: 4,
        sub_area_id: 2837,
        subarea_name: "Sector 76"
      }
    ],
    587: [
      {
        hub_id: 4,
        sub_area_id: 2838,
        subarea_name: "Sector 77"
      }
    ],
    588: [
      {
        hub_id: 4,
        sub_area_id: 2839,
        subarea_name: "Sector 78"
      }
    ],
    589: [
      {
        hub_id: 4,
        sub_area_id: 2840,
        subarea_name: "Sector 79"
      }
    ],
    590: [
      {
        hub_id: 4,
        sub_area_id: 2841,
        subarea_name: "Sector 80"
      }
    ],
    591: [
      {
        hub_id: 4,
        sub_area_id: 2842,
        subarea_name: "Sector 81"
      },
      {
        hub_id: 4,
        sub_area_id: 3650,
        subarea_name: "Vipul lavanya"
      }
    ],
    592: [
      {
        hub_id: 4,
        sub_area_id: 2843,
        subarea_name: "Sector 81A"
      }
    ],
    593: [
      {
        hub_id: 4,
        sub_area_id: 2844,
        subarea_name: "Mapsco Royal Ville"
      },
      {
        hub_id: 4,
        sub_area_id: 3018,
        subarea_name: "Mapsco Casa Billa"
      },
      {
        hub_id: 4,
        sub_area_id: 3019,
        subarea_name: "Mapsco Paradise"
      },
      {
        hub_id: 4,
        sub_area_id: 3162,
        subarea_name: "Seven Lamps Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 3597,
        subarea_name: "Sector 82"
      }
    ],
    594: [
      {
        hub_id: 4,
        sub_area_id: 2845,
        subarea_name: "Sector 82A"
      },
      {
        hub_id: 4,
        sub_area_id: 3174,
        subarea_name: "The Primus"
      }
    ],
    595: [
      {
        hub_id: 4,
        sub_area_id: 2846,
        subarea_name: "Sector 83"
      },
      {
        hub_id: 4,
        sub_area_id: 3083,
        subarea_name: "Vatica G21"
      }
    ],
    596: [
      {
        hub_id: 4,
        sub_area_id: 2847,
        subarea_name: "Sector 84"
      },
      {
        hub_id: 4,
        sub_area_id: 3137,
        subarea_name: "Gurgaon One"
      }
    ],
    597: [
      {
        hub_id: 4,
        sub_area_id: 2848,
        subarea_name: "Sector 85"
      }
    ],
    598: [
      {
        hub_id: 4,
        sub_area_id: 2849,
        subarea_name: "Sector 86"
      },
      {
        hub_id: 4,
        sub_area_id: 3020,
        subarea_name: "Microtech Greenburg"
      }
    ],
    599: [
      {
        hub_id: 4,
        sub_area_id: 2850,
        subarea_name: "Sector 87"
      }
    ],
    600: [
      {
        hub_id: 4,
        sub_area_id: 2851,
        subarea_name: "Sector 88"
      }
    ],
    601: [
      {
        hub_id: 4,
        sub_area_id: 2852,
        subarea_name: "Sector 88A"
      },
      {
        hub_id: 4,
        sub_area_id: 3629,
        subarea_name: "Godrej Ossias"
      },
      {
        hub_id: 4,
        sub_area_id: 3630,
        subarea_name: "Godrej Icon"
      }
    ],
    602: [
      {
        hub_id: 4,
        sub_area_id: 2853,
        subarea_name: "Sector 88B"
      }
    ],
    603: [
      {
        hub_id: 4,
        sub_area_id: 2854,
        subarea_name: "Sector 89"
      }
    ],
    604: [
      {
        hub_id: 4,
        sub_area_id: 2855,
        subarea_name: "Sector 89A"
      }
    ],
    605: [
      {
        hub_id: 4,
        sub_area_id: 2856,
        subarea_name: "Sector 89B"
      }
    ],
    606: [
      {
        hub_id: 4,
        sub_area_id: 2857,
        subarea_name: "Sector 90"
      },
      {
        hub_id: 4,
        sub_area_id: 3010,
        subarea_name: "Best Tech Park Sanskruti"
      },
      {
        hub_id: 4,
        sub_area_id: 3011,
        subarea_name: "Sanskruti Apartment"
      }
    ],
    607: [
      {
        hub_id: 4,
        sub_area_id: 2858,
        subarea_name: "Sector 91"
      }
    ],
    608: [
      {
        hub_id: 4,
        sub_area_id: 2859,
        subarea_name: "Sector 92"
      }
    ],
    609: [
      {
        hub_id: 4,
        sub_area_id: 2860,
        subarea_name: "Sector 93"
      },
      {
        hub_id: 4,
        sub_area_id: 3631,
        subarea_name: "Signature Global Orchid Avenue"
      }
    ],
    610: [
      {
        hub_id: 4,
        sub_area_id: 2861,
        subarea_name: "Sector 94"
      }
    ],
    611: [
      {
        hub_id: 4,
        sub_area_id: 2862,
        subarea_name: "Sector 95"
      }
    ],
    612: [
      {
        hub_id: 4,
        sub_area_id: 2863,
        subarea_name: "Sector 95A"
      }
    ],
    613: [
      {
        hub_id: 4,
        sub_area_id: 2864,
        subarea_name: "Sector 95B"
      }
    ],
    614: [
      {
        hub_id: 4,
        sub_area_id: 2865,
        subarea_name: "Sector 97"
      }
    ],
    615: [
      {
        hub_id: 4,
        sub_area_id: 2866,
        subarea_name: "Sector 98"
      }
    ],
    616: [
      {
        hub_id: 4,
        sub_area_id: 2867,
        subarea_name: "Sector 99"
      }
    ],
    617: [
      {
        hub_id: 4,
        sub_area_id: 2868,
        subarea_name: "Sector 99A"
      }
    ],
    618: [
      {
        hub_id: 4,
        sub_area_id: 2869,
        subarea_name: "Sector 100"
      }
    ],
    619: [
      {
        hub_id: 4,
        sub_area_id: 2870,
        subarea_name: "Sector 101"
      }
    ],
    620: [
      {
        hub_id: 4,
        sub_area_id: 2871,
        subarea_name: "Sector 102"
      }
    ],
    621: [
      {
        hub_id: 4,
        sub_area_id: 2872,
        subarea_name: "Sector 102A"
      }
    ],
    622: [
      {
        hub_id: 4,
        sub_area_id: 2873,
        subarea_name: "Sector 103"
      },
      {
        hub_id: 4,
        sub_area_id: 3004,
        subarea_name: "Satya The Hermitage"
      },
      {
        hub_id: 4,
        sub_area_id: 3005,
        subarea_name: "India Bulles Centrum"
      }
    ],
    623: [
      {
        hub_id: 4,
        sub_area_id: 2874,
        subarea_name: "Sector 104"
      }
    ],
    624: [
      {
        hub_id: 4,
        sub_area_id: 2875,
        subarea_name: "Sector 105"
      }
    ],
    625: [
      {
        hub_id: 4,
        sub_area_id: 2876,
        subarea_name: "Sector 106"
      }
    ],
    626: [
      {
        hub_id: 4,
        sub_area_id: 2877,
        subarea_name: "Sector 107"
      },
      {
        hub_id: 4,
        sub_area_id: 3588,
        subarea_name: "M3M Woodshire"
      }
    ],
    627: [
      {
        hub_id: 4,
        sub_area_id: 2878,
        subarea_name: "Sector 108"
      },
      {
        hub_id: 4,
        sub_area_id: 3002,
        subarea_name: "Raheja Vedanta"
      }
    ],
    628: [
      {
        hub_id: 4,
        sub_area_id: 2879,
        subarea_name: "Sector 109"
      },
      {
        hub_id: 4,
        sub_area_id: 3003,
        subarea_name: "Brisk Lumbini"
      },
      {
        hub_id: 4,
        sub_area_id: 3594,
        subarea_name: "Raheja atharva"
      }
    ],
    629: [
      {
        hub_id: 4,
        sub_area_id: 2880,
        subarea_name: "Sector 110"
      },
      {
        hub_id: 4,
        sub_area_id: 3653,
        subarea_name: "India bulls enigma"
      }
    ],
    630: [
      {
        hub_id: 4,
        sub_area_id: 2881,
        subarea_name: "Sector 110A"
      }
    ],
    631: [
      {
        hub_id: 4,
        sub_area_id: 2882,
        subarea_name: "Sector 111"
      }
    ],
    632: [
      {
        hub_id: 4,
        sub_area_id: 2883,
        subarea_name: "Sector 112"
      }
    ],
    633: [
      {
        hub_id: 4,
        sub_area_id: 2884,
        subarea_name: "Sector 113"
      }
    ],
    634: [
      {
        hub_id: 4,
        sub_area_id: 2885,
        subarea_name: "Sector 114"
      }
    ],
    635: [
      {
        hub_id: 4,
        sub_area_id: 2886,
        subarea_name: "Sector 115"
      }
    ],
    637: [
      {
        hub_id: 3,
        sub_area_id: 1782,
        subarea_name: "Bestech Park View City 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1783,
        subarea_name: "Bestech Park View City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1784,
        subarea_name: "Central Park Resorts"
      },
      {
        hub_id: 3,
        sub_area_id: 1785,
        subarea_name: "Chd Avenue"
      },
      {
        hub_id: 3,
        sub_area_id: 1786,
        subarea_name: "Close South"
      },
      {
        hub_id: 3,
        sub_area_id: 1787,
        subarea_name: "D Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1788,
        subarea_name: "Jmd Garden"
      },
      {
        hub_id: 3,
        sub_area_id: 1789,
        subarea_name: "Malibu Town"
      },
      {
        hub_id: 3,
        sub_area_id: 1790,
        subarea_name: "Orchid Petal"
      },
      {
        hub_id: 3,
        sub_area_id: 1791,
        subarea_name: "Parsvnath Green Ville"
      },
      {
        hub_id: 3,
        sub_area_id: 1792,
        subarea_name: "Shani Enclave Part 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1793,
        subarea_name: "Shispal Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1794,
        subarea_name: "Spaze Privy"
      },
      {
        hub_id: 3,
        sub_area_id: 1795,
        subarea_name: "Spaze Privy Society"
      },
      {
        hub_id: 3,
        sub_area_id: 1796,
        subarea_name: "Tata Primanti"
      },
      {
        hub_id: 3,
        sub_area_id: 1797,
        subarea_name: "Tatvam Villa"
      },
      {
        hub_id: 3,
        sub_area_id: 1798,
        subarea_name: "Tulip Violet"
      },
      {
        hub_id: 3,
        sub_area_id: 1799,
        subarea_name: "Vatika City"
      },
      {
        hub_id: 3,
        sub_area_id: 1800,
        subarea_name: "Vipul Green"
      },
      {
        hub_id: 3,
        sub_area_id: 2888,
        subarea_name: "Sohna Road"
      }
    ],
    638: [
      {
        hub_id: 3,
        sub_area_id: 1801,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1802,
        subarea_name: "D Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1803,
        subarea_name: "G Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1804,
        subarea_name: "J Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1805,
        subarea_name: "K Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1806,
        subarea_name: "M Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1807,
        subarea_name: "N Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1808,
        subarea_name: "O Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1809,
        subarea_name: "Q Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1810,
        subarea_name: "Rakshak Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1811,
        subarea_name: "South City 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1812,
        subarea_name: "The Palms"
      },
      {
        hub_id: 3,
        sub_area_id: 1813,
        subarea_name: "The Retreat Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1814,
        subarea_name: "Unitech Palms"
      },
      {
        hub_id: 3,
        sub_area_id: 1815,
        subarea_name: "Unitech Retreat"
      }
    ],
    639: [
      {
        hub_id: 3,
        sub_area_id: 1816,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1817,
        subarea_name: "A1 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1818,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1819,
        subarea_name: "D Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1820,
        subarea_name: "G Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1821,
        subarea_name: "G1 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1822,
        subarea_name: "I Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1823,
        subarea_name: "Q Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1824,
        subarea_name: "South City 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1825,
        subarea_name: "The Lilac 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1826,
        subarea_name: "The Lilac 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1827,
        subarea_name: "Uppal Southend"
      }
    ],
    640: [
      {
        hub_id: 3,
        sub_area_id: 2889,
        subarea_name: "Sushant Lok Extension"
      }
    ],
    641: [
      {
        hub_id: 3,
        sub_area_id: 1828,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1829,
        subarea_name: "A1 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1830,
        subarea_name: "B Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1833,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1834,
        subarea_name: "C1 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1835,
        subarea_name: "C3 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1836,
        subarea_name: "E Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1837,
        subarea_name: "Maple Crescent"
      },
      {
        hub_id: 3,
        sub_area_id: 1838,
        subarea_name: "Maple Heights"
      },
      {
        hub_id: 3,
        sub_area_id: 1839,
        subarea_name: "Regency Park 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1842,
        subarea_name: "Sushant Apartment"
      },
      {
        hub_id: 3,
        sub_area_id: 1843,
        subarea_name: "Sushant Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1844,
        subarea_name: "The Laburnum"
      },
      {
        hub_id: 3,
        sub_area_id: 1845,
        subarea_name: "Whispering Meadows"
      },
      {
        hub_id: 3,
        sub_area_id: 2890,
        subarea_name: "Sushant Lok Phase 1"
      }
    ],
    642: [
      {
        hub_id: 3,
        sub_area_id: 1846,
        subarea_name: "A1 Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1847,
        subarea_name: "F Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1848,
        subarea_name: "Huda Plot"
      },
      {
        hub_id: 3,
        sub_area_id: 2891,
        subarea_name: "Sushant Lok Phase 2"
      }
    ],
    643: [
      {
        hub_id: 3,
        sub_area_id: 1849,
        subarea_name: "A Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1850,
        subarea_name: "C Block"
      },
      {
        hub_id: 3,
        sub_area_id: 1851,
        subarea_name: "Florence Marvel Villa"
      },
      {
        hub_id: 3,
        sub_area_id: 1852,
        subarea_name: "Oriental Villas"
      },
      {
        hub_id: 3,
        sub_area_id: 1853,
        subarea_name: "Sushant Residency"
      },
      {
        hub_id: 3,
        sub_area_id: 2892,
        subarea_name: "Sushant Lok Phase 3"
      }
    ],
    644: [
      {
        hub_id: 3,
        sub_area_id: 2893,
        subarea_name: "Udyog Vihar Phase 1"
      }
    ],
    645: [
      {
        hub_id: 3,
        sub_area_id: 2894,
        subarea_name: "Udyog Vihar Phase 2"
      }
    ],
    646: [
      {
        hub_id: 3,
        sub_area_id: 2895,
        subarea_name: "Udyog Vihar Phase 3"
      }
    ],
    647: [
      {
        hub_id: 3,
        sub_area_id: 2896,
        subarea_name: "Udyog Vihar Phase 4"
      }
    ],
    648: [
      {
        hub_id: 3,
        sub_area_id: 2897,
        subarea_name: "Udyog Vihar Phase 5"
      }
    ],
    649: [
      {
        hub_id: 3,
        sub_area_id: 1854,
        subarea_name: "Beverly Park 1"
      },
      {
        hub_id: 3,
        sub_area_id: 1855,
        subarea_name: "Beverly Park 2"
      },
      {
        hub_id: 3,
        sub_area_id: 1856,
        subarea_name: "Essel Tower"
      },
      {
        hub_id: 3,
        sub_area_id: 1857,
        subarea_name: "Garden Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1858,
        subarea_name: "Heritage City"
      },
      {
        hub_id: 3,
        sub_area_id: 1859,
        subarea_name: "Maruti Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1860,
        subarea_name: "Mg Road"
      },
      {
        hub_id: 3,
        sub_area_id: 1861,
        subarea_name: "Plot No 2a"
      },
      {
        hub_id: 3,
        sub_area_id: 1862,
        subarea_name: "Sahara Grace"
      },
      {
        hub_id: 3,
        sub_area_id: 1863,
        subarea_name: "Saraswati Vihar"
      },
      {
        hub_id: 3,
        sub_area_id: 1864,
        subarea_name: "Veer Savarkar Block"
      }
    ],
    650: [
      {
        hub_id: 3,
        sub_area_id: 1865,
        subarea_name: "Garden Estate"
      },
      {
        hub_id: 3,
        sub_area_id: 1866,
        subarea_name: "Sikendepur Village"
      },
      {
        hub_id: 3,
        sub_area_id: 2898,
        subarea_name: "Guru Dronacharya Metro"
      }
    ],
    652: [
      {
        hub_id: 4,
        sub_area_id: 1877,
        subarea_name: "Chander Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 1878,
        subarea_name: "Hewo Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1879,
        subarea_name: "Hope Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1880,
        subarea_name: "Park View Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1881,
        subarea_name: "Patel Nagar"
      },
      {
        hub_id: 4,
        sub_area_id: 1882,
        subarea_name: "Premier Urban Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1883,
        subarea_name: "Rail Vihar"
      },
      {
        hub_id: 4,
        sub_area_id: 1884,
        subarea_name: "Shakti Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 1885,
        subarea_name: "Urban Apartment"
      },
      {
        hub_id: 4,
        sub_area_id: 2900,
        subarea_name: "Sector 15 Part 2"
      }
    ],
    797: [
      {
        hub_id: 4,
        sub_area_id: 3171,
        subarea_name: "DLF New Town Height"
      }
    ],
    879: [
      {
        hub_id: 3,
        sub_area_id: 3339,
        subarea_name: "Sector 17A"
      }
    ],
    966: [
      {
        hub_id: 3,
        sub_area_id: 3619,
        subarea_name: "Sector 17C"
      }
    ]
  };

  const handleAreaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAreaId = event.target.value;
    setSelectedArea(selectedAreaId);
    if (selectedAreaId && subAreaData[selectedAreaId]) {
      setSubareas(subAreaData[selectedAreaId]);
    } else {
      setSubareas([]);
    }
  };

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
    onToggleOffcanvas(!showOffcanvas); // Pass the state to the parent
  };

  useEffect(() => {
    const updatePlacement = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 768) {
        setPlacement('end'); // Desktop opens from right
      } else {
        setPlacement('bottom'); // Mobile opens from bottom
      }
    };

    updatePlacement();
    window.addEventListener('resize', updatePlacement);

    return () => window.removeEventListener('resize', updatePlacement);
  }, []);

  const navigateToProducts = () => {
    setShowOffcanvas(false);
    onToggleOffcanvas(false); // Pass the state to the parent
    navigate('/product-list');
  };

  const calculateSubtotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const deliveryCharges = 'Free';
  const totalPay = calculateSubtotal();

  return (
    <>
      <button className="floating-cart-button" onClick={toggleOffcanvas}>
        <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: '20px' }} />
        {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
      </button>

      <Offcanvas
        show={showOffcanvas}
        onHide={toggleOffcanvas}
        placement={placement}
        className="cart-offcanvas"
      >
        <Offcanvas.Header closeButton>
          {view === 'address' ? (
            <>
              <button
                className="back-button"
                onClick={() => setView('cart')}
              >
                <FontAwesomeIcon style={{ color: 'white', marginRight: '20px' }} icon={faArrowLeft} />
              </button>
              <Offcanvas.Title>Enter Address</Offcanvas.Title>
            </>
          ) : (
            <Offcanvas.Title>Your Cart</Offcanvas.Title>
          )}
        </Offcanvas.Header>


        <Offcanvas.Body>
          {view === 'cart' ? (
            <>
              {cart.length === 0 ? (
                <div className="empty-cart-section">
                  <img src={emptyCart} alt="Empty Cart" className="empty-cart" />
                  <h4 className="empty-cart-title">Your Cart is Empty</h4>
                  <Button
                    variant="primary"
                    className="browse-products-btn"
                    onClick={navigateToProducts}
                  >
                    Browse Products
                  </Button>
                </div>
              ) : (
                <>
                  <div className="cart-content">
                    {cart.map((item) => (
                      <div key={item.id} className="cart-item">
                        <img src={item.image} alt={item.title} className="cart-item-image" />
                        <div className="cart-item-info">
                          <h4>{item.title}</h4>
                          <p>Rs {item.price}</p>
                        </div>
                        <div className="quantity-controls">
                          <button onClick={() => decrementQuantity(item.id)} className="quantity-btn">
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <span className="quantity">{item.quantity}</span>
                          <button onClick={() => incrementQuantity(item.id)} className="quantity-btn">
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="bill-summary">
                    <h4>Bill Summary</h4>

                    <p>
                      <span className="label">Subtotal:</span>
                      <span className="value">Rs {calculateSubtotal()}</span>
                    </p>

                    <p>
                      <span className="label">Delivery Charges:</span>
                      <span className="value" style={{ color: '#2ab939' }}> {deliveryCharges}</span>
                    </p>

                    <div className="dotted-line"></div>

                    <p>
                      <span className="label"><strong>Total Pay:</strong></span>
                      <span className="value" style={{ fontWeight: 600 }}>Rs {totalPay}</span>
                    </p>

                    <Button
                      style={{ background: '#2ab939', border: 'none', height: '40px' }}
                      variant="primary"
                      className="next-btn"
                      onClick={() => setView('address')}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="address-form">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" placeholder="Enter your phone number" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" value="Gurugram" placeholder="Enter your city" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter your address" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Area</Form.Label>
                  <Form.Select value={selectedArea} onChange={handleAreaChange}>
                    {areaData.map((area) => (
                      <option key={area.area_id} value={area.area_id}>
                        {area.area_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Subarea</Form.Label>
                  <Form.Select>
                    {subareas.length > 0 ? (
                      subareas.map((subarea) => (
                        <option key={subarea.sub_area_id} value={subarea.sub_area_id}>
                          {subarea.subarea_name}
                        </option>
                      ))
                    ) : (
                      <option value="">Select a subarea</option>
                    )}
                    {/* Add more subareas here */}
                  </Form.Select>
                </Form.Group>

                <Button
                  style={{
                    background: '#2ab939',
                    border: 'none',
                    height: '40px',
                    width: '100%',
                  }}
                  variant="success"
                  type="submit"
                  className="submit-btn"
                >
                  Place Order
                </Button>
              </Form>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}