import React from 'react';
import logo from '../../assets/img/logo/logo.png';
import android from '../../assets/img/app/android.png'
import ios from '../../assets/img/app/ios.png'
import wheat from '../../assets/img/footer/footer-wheat.png';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <footer className="footer-section overflow-hidden position-relative footer-style1">
        <div className="footer-widgets-wrapper footer-widget-wrapperv01">
          <div className="container">
            <div className="row g-md-4 g-4 justify-content-between">
              <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".3s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <a href="/" className="footer-logo">
                      <img src={logo} width={120} height={60} alt="logo-img" />
                    </a>
                  </div>
                  <div className="footer-content">
                    <p className="pre-pragraph">
                      We deliver fresh, premium dairy products with care, <br /> bringing the best of farm goodness straight to your table.
                    </p>
                    <div className="social-wrapper social-empact d-flex align-items-center">
                      <a href="#" className="white-clr"><i className="white-clr fab fa-facebook-f"></i></a>
                      <a href="#" className="white-clr">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".5s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h3 className="white-clr">Download App</h3>
                  </div>
                  <ul className="list-area" style={{ paddingLeft: '0px' }}>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.jeevandhara.user&hl=en">
                        <img src={android} width={220} height={60} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.apple.com/in/search/purity-harvest?src=globalnav">
                        <img src={ios} width={220} height={60} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".5s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h3 className="white-clr">Link</h3>
                  </div>
                  <ul className="list-area" style={{ paddingLeft: '0px' }}>
                    <li>
                      <Link to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <a href="/">
                        Refer & Earn
                      </a>
                    </li>
                    <li>
                      <a href="/faq">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay=".5s">
                <div className="single-footer-widget">
                  <div className="widget-head">
                    <h3 className="white-clr">Contact</h3>
                  </div>
                  <ul className="list-area list-contact" style={{ paddingLeft: '0px' }}>
                    <li>
                      <a href="#">
                        <i className="fa-solid fa-envelope"></i>
                        support@purityharvest.in
                      </a>
                    </li>
                    <li>
                      <a href="#" className="a">
                        <i className="fa-solid fa-location-dot"></i>
                        Sarti Agri and Dairy Farms, <br /> Rewari, Kosli ( 123302 )
                      </a>
                    </li>
                    <li>
                      <a href="#" className="a">
                        <i className="fa-solid fa-phone"></i>
                        (+91) 8168024581
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div
              className="footer-wrapper footer-wrapperv01 d-md-flex d-grid gap-md-0 gap-2 align-items-center justify-content-md-between justify-content-center text-md-start text-center">
              <p className="wow fadeInLeft color-2" data-wow-delay=".3s">
                © Purity Harvest {new Date().getFullYear()}. All Rights Reserved
              </p>
              <ul className="footer-menu wow fadeInRight" data-wow-delay=".5s" style={{ paddingLeft: '0px' }}>
                <li>
                  <Link to="/terms-and-conditions">
                    Terms & Condition
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <img src={wheat} alt="img" className="footer-wheat position-absolute" />

      </footer>

    </>
  )
}
