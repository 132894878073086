import { useState } from 'react';
import logo from '../../assets/img/logo/ph-logo.png';
import stickyLogo from '../../assets/img/logo/logo.png';
import NavMenu from '../../layouts/headers/menu/NavMenu';
import Offcanvas from '../common/Offcanvas';
import useSticky from '../../hooks/use-sticky';
import { Link } from 'react-router-dom';

function HomeHeader() {

  const { sticky } = useSticky()
  const [open, setOpen] = useState(false)

  return (
    <>
      <header id="header-sticky" className={`header-1 header-3 ${sticky ? "sticky" : ""}`}>
        <div className="container">
          <div className="mega-menu-wrapper">
            <div className="header-main">
              <div className="header-left">
                <div className="logo">
                  <Link to="/" className="header-logo">
                    <img src={sticky ? stickyLogo : logo} width={130} height={60} alt="logo-img" />
                  </Link>
                </div>
              </div>
              <div className="header-right d-flex justify-content-end align-items-center">
                <div className="mean__menu-wrapper">
                  <div className="main-menu d-none d-xl-block">
                    <nav id="mobile-menu">
                      <NavMenu sticky={sticky}/>
                    </nav>
                  </div>
                </div>
                <div className="shop-adjust">
                  <div className="header-button d-sm-block d-none">
                    <a href="/contact" className="download-app-btn d-center cmn-white-clr">
                      Download the app and claim offers
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
                <div className="header__hamburger d-xl-none my-auto">
                  <div className="sidebar__toggle" onClick={() => setOpen(!open)}>
                    <i className="fas fa-bars" style={{color: sticky ? 'white' : '#1f4e3d'}}></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Offcanvas open={open} setOpen={setOpen} />
    </>
  )
}

export default HomeHeader;
