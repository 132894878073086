import React from 'react';
import man from '../../assets/img/about/choose-man.png';
import food from '../../assets/img/about/f-food.png';
import lfood from '../../assets/img/about/l-food.png';
import tfood from '../../assets/img/about/t-food.png';

export default function AboutPage() {

  return (
    <>
      <section className="about-section style-v01 white-bg space-top">
        <div className="container">
          <div className="about-wrapperv3">
            <div className="row g-4 align-items-center justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-8 order-md-0 order-1">
                <div className="about-thumv01 position-relative">
                  <img src={man} alt="img" className="mimg" />
                  <img src={food} alt="img" className="f-food" />
                  <img src={lfood} alt="img" className="l-food wow fadeInLeft" data-wow-delay=".5s" />
                  <img src={tfood} alt="img" className="t-food wow fadeInLeft" data-wow-delay=".7s" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="about-contentv1">
                  <div className="section-title mb-40">
                    <h5 className="p1-clr text-uppercase wow fadeInLeft" data-wow-delay="0.4s">
                      Why Choose us
                    </h5>
                    <h2 className="wow fadeInDown" data-wow-delay=".3s">
                      13 Years of Vision, Growth, and Excellence
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Our remarkable journey began roughly 13 years ago when we established our dairy farm, initially starting with just 5 cows. Fast forward to today, and we are proud to say that our farm is now home to more than 80 cows. </p>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      From our early beginnings until 2015, we devoted ourselves to learning the nuances of dairy farming, exploring various facets to understand the most effective and ethical practices for producing high-quality milk. This period of dedicated learning was crucial in laying the foundation for what was to come.
                    </p>  <p className="wow fadeInUp" data-wow-delay=".4s">
                      In 2016, we took a bold step forward by introducing doorstep milk delivery services. Our journey into this new endeavor commenced with the use of conventional milk cans for delivery. However, as our farm and delivery service evolved, we came to realize the numerous benefits of glass bottles for milk delivery. Consequently, we made the strategic decision to transition to glass packaging, prioritizing both the freshness and sustainability of our products.
                    </p>  
                     {/* <p className="wow fadeInUp" data-wow-delay=".4s">
                      Beyond our own farm, we also embarked on a mission to support our community by procuring buffalo milk from trusted local farmers. This collaboration not only strengthened our ties with our fellow farmers but also contributed to the well-being of our community at large.
                    </p>  <p className="wow fadeInUp" data-wow-delay=".4s">
                      Today, we have meticulously honed a comprehensive and efficient method that spans the entire journey from milk procurement to doorstep delivery. This method includes rigorous testing, precise chilling, and secure packaging, all designed to ensure that only the highest quality dairy products reach our customers.
                    </p>   <p className="wow fadeInUp" data-wow-delay=".4s">
                      We take immense pride in our journey, from those humble beginnings of learning to becoming a trusted source of pure, wholesome dairy goodness for our community. And as we continue to grow, we are steadily expanding our product range to offer even more fresh and exceptional options to our valued customers
                    </p> */}
                    <div className="progress_bar d-grid gap-xxl-4 gap-4">
                      <div className="progress_bar_item">
                        <div className="per-title d-flex align-items-center justify-content-between">
                          <div className="item_label p900-clr">FarmSmart Innovations</div>
                        </div>
                        <div className="item_bar">
                          <div className="progress" data-progress="70" style={{ width: "70%" }}></div>
                        </div>
                      </div>
                      <div className="progress_bar_item">
                        <div className="per-title d-flex align-items-center justify-content-between">
                          <div className="item_label p900-clr">CropCare Solutions</div>
                        </div>
                        <div className="item_bar">
                          <div className="progress" data-progress="80" style={{ width: "80%" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
