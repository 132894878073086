

const faq_data = [
  {
    id: "1",
    question: "  How do I create a vegetable Agriculture?",
    answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text",
  },

  {
    id: "2",
    question: "  How Farming during the winter months?",
    answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text",
  },

  {
    id: "3",
    question: "  How do I prevent in my Argiculture?",
    answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text",
  },

  {
    id: "4",
    question: "  What are the prerequisites course?",
    answer: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text",
  },
]

export default faq_data