
import React from 'react'
import HeaderThree from '../../layouts/headers/HeaderThree';
import Breadcrumnd from '../common/Breadcrumnd';
import Wrapper from '../../layouts/Wrapper';
import PrivacyPolicy from './PrivacyPolicy';

export default function Privacy() {
    return (
        <Wrapper>
            <HeaderThree />
            <Breadcrumnd title="Privacy Policy" subtitle="Privacy Policy" />
            <PrivacyPolicy />
        </Wrapper>
    )
}
