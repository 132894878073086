import React from 'react'
import Wrapper from '../../layouts/Wrapper'
import HeaderThree from '../../layouts/headers/HeaderThree'
import Breadcrumnd from '../common/Breadcrumnd'
import Subscribe from '../about/Subscribe'
import ProductDetailsArea from './ProductDetailsArea'

export default function ProductDetails() {
  return (
    <Wrapper>
      <HeaderThree />
      <ProductDetailsArea />  
    </Wrapper>
  )
}