import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import './HomeBanner.scss';

// Images for the carousel
import banner1 from '../../assets/img/banner/1.png';
import banner2 from '../../assets/img/banner/2.png';
import banner3 from '../../assets/img/banner/2.png';
import banner4 from '../../assets/img/banner/4.png';
import banner5 from '../../assets/img/banner/5.png';

export default function HomeBanner() {
  return (
    <>
      <section className="banner-section style-v3 overflow-hidden position-relative">
        <Swiper
          modules={[Navigation, Pagination, Autoplay, EffectFade]}
          spaceBetween={0}
          effect="fade"
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false
          }}
          pagination={{ clickable: true }}
          navigation={true}
          className="home-banner-carousel"
        >
          {/* Slide 1 */}
          <SwiperSlide>
            <img src={banner1} alt="Banner 1" className="banner-image" />
          </SwiperSlide>

          {/* Slide 2 */}
          <SwiperSlide>
            <img src={banner2} alt="Banner 2" className="banner-image" />
          </SwiperSlide>

          {/* Slide 3 */}
          <SwiperSlide>
            <img src={banner3} alt="Banner 3" className="banner-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={banner4} alt="Banner 3" className="banner-image" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={banner5} alt="Banner 3" className="banner-image" />
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  )
}