import React, { useState } from "react";
import menu_data from "../../../data/menu_data";
import { Link } from "react-router-dom";

export default function MobileMenu() {
	const [navTitle, setNavTitle] = useState("");

	return (
		<>
			<div className="mean-bar">
				<nav className="mean-nav">
					<ul>
						{menu_data.map((item, i) => (
							<li
								key={i}
								className={`has-dropdown menu-thumb ${
									navTitle === item.title ? "dropdown-opened" : ""
								}`}
							>
								<Link to={item.link}>
									{item.title} 
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</div>
		</>
	);
}
