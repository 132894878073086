
import demo_img_1 from "@/assets/img/header/home-1.jpg";
import demo_img_2 from "@/assets/img/header/home-2.jpg";
import demo_img_3 from "@/assets/img/header/home-3.jpg";

interface DataType {
	id: number;
	title: string;
	link: string;
	has_dropdown?: boolean;
	sub_menus?: {
		link: string;
		title: string;
		demo_img?: '';
	}[];
}

// menu data
const menu_data: DataType[] = [
	{
		id: 1,
		title: "Home",
		link: "/",
		has_dropdown: false,
	},
	{
		id: 2,
		title: "Why Purity Harvest",
		link: "/about",
		has_dropdown: false,
	},
	{
		id: 3,
		title: "Products",
		link: "/product-list",
		has_dropdown: false,
	},
	{
		id: 4,
		title: "Refer & Earn",
		link: "/contact-us",
		has_dropdown: false,
	},
	{
		id: 5,
		title: "Contact Us",
		link: "/contact-us",
		has_dropdown: false,
	},
];
export default menu_data;
