import React, { useState } from 'react'
import NavMenu from './menu/NavMenu'
import useSticky from '../../hooks/use-sticky'
import Offcanvas from '../../components/common/Offcanvas'
import logo from '../../assets/img/logo/ph-logo.png';
import whiteLogo from '../../assets/img/logo/ph-logo.png';
import chat from '../../assets/img/icon/chat.svg';
import { Link } from 'react-router-dom';

export default function HeaderThree() {

  const { sticky } = useSticky()
  const [open, setOpen] = useState(false)




  return (
    <>
      <div id="header-sticky" className={`header-common-adjustment ${sticky ? "sticky" : ""}`}>
        <Link to="/" className="logo-v04">
          <img src={logo} width={150} height={75} alt="img" />
        </Link>
        <div className="topheader-mainheader">

          <div className="header-top-section topcmn-style d-lg-block d-none">
            <div className="container">
              <div className="header-top-wrapper">
                <ul className="contact-list" style={{ marginBottom: '0px' }}>
                  <li>
                    <a href="#"><i className="fa-solid fa-envelope p1-clr"></i>
                      support@purityharvest.in</a>
                  </li>
                  <li>
                    <a href="#" className="a"><i className="fa-solid fa-location-dot p1-clr"></i> Sarti Agri and Dairy Farms, Rewari, Kosli ( 123302 )
                    </a>
                  </li>
                </ul>
                <div className="social-wrapper d-flex align-items-center">
                  <a href="#" className="white-clr">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" className="white"><i className="white fab fa-facebook-f"></i></a>
                </div>
              </div>
            </div>
          </div>


          <header className="header-v4">
            <div className="container">
              <div className="mega-menu-wrapper">
                <div className="header-main">
                  <div className="header-left d-xl-none d-block">
                    <div className="logo">
                      <a href="/" className="header-logo">
                        <img src={whiteLogo} width={120} height={60} alt="logo-img" />
                      </a>
                    </div>
                  </div>
                  <div className="header-right d-flex justify-content-between w-100 align-items-center">
                    <div className="mean__menu-wrapper">
                      <div className="main-menu d-none d-xl-block">
                        <nav id="mobile-menu">
                          <NavMenu />
                        </nav>
                      </div>
                    </div>
                    <div className="common-adjust-toggle">
                      <a href="#" className="header-help">
                        <img src={chat} alt="img" />
                        <span className="d-grid">
                          <span className="need">
                            Need help?
                          </span>
                          <span className="call">
                            +91 8168024581
                          </span>
                        </span>
                      </a>
                      <div className="header__hamburger my-auto">
                        <div onClick={() => setOpen(!open)} className="sidebar__toggle d-center">
                          <svg width="22" height="10" viewBox="0 0 22 10" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5879 1L21.1761 1" stroke="#FDD61F" strokeWidth="2" />
                            <path d="M0 8.41406L21.1765 8.41406" stroke="#FDD61F"
                              strokeWidth="2" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>

      <Offcanvas open={open} setOpen={setOpen} />

    </>
  )
}
