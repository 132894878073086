import React from 'react';
import man from '../../assets/img/about/choose-man.png';
import food from '../../assets/img/about/f-food.png';
import lfood from '../../assets/img/about/l-food.png';
import tfood from '../../assets/img/about/t-food.png';

export default function AboutHome() {

  return (
    <>
      <section className="about-section style-v01 white-bg" style={{marginTop: '40px'}}>
        <div className="container">
          <div className="about-wrapperv3">
            <div className="row g-4 align-items-center justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-8 order-md-0 order-1">
                <div className="about-thumv01 position-relative">
                  <img src={man} alt="img" className="mimg" />
                  <img src={food} alt="img" className="f-food" />
                  <img src={lfood} alt="img" className="l-food wow fadeInLeft" data-wow-delay=".5s" />
                  <img src={tfood} alt="img" className="t-food wow fadeInLeft" data-wow-delay=".7s" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="about-contentv1">
                  <div className="section-title mb-40">
                    <h5 className="p1-clr text-uppercase wow fadeInLeft" data-wow-delay="0.4s">
                      Why Choose us
                    </h5>
                    <h2 className="wow fadeInDown" data-wow-delay=".3s">
                      13 Years of Vision, Growth, and Excellence
                    </h2>
                    <p className="wow fadeInUp" data-wow-delay=".4s">
                      Our remarkable journey began roughly 13 years ago when we established our dairy farm, initially starting with just 5 cows. Fast forward to today, and we are proud to say that our farm is now home to more than 80 cows.... </p>
                    <div className="progress_bar d-grid gap-xxl-4 gap-4">
                      <div className="progress_bar_item">
                        <div className="per-title d-flex align-items-center justify-content-between">
                          <div className="item_label p900-clr">Farm Expansion Excellence</div>
                        </div>
                        <div className="item_bar">
                          <div className="progress" data-progress="80" style={{ width: "80%" }}></div>
                        </div>
                      </div>
                      <div className="progress_bar_item">
                        <div className="per-title d-flex align-items-center justify-content-between">
                          <div className="item_label p900-clr">Dairy Quality Assurance</div>
                        </div>
                        <div className="item_bar">
                          <div className="progress" data-progress="99" style={{ width: "99%" }}></div>
                        </div>
                      </div>
                    </div>
                    <a href="/about" className="cmn-btn">
                      Read More
                      <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
