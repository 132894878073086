

import React from 'react'

export default function PrivacyPolicy() {
    return (
        <>
            <section className="contact-infosectionv1 space-top overflow-hidden space-bottom">
                <div className="container">
                    <div className="row g-lg-4 g-3 justify-content-center">
                        <h3>Privacy Policy</h3>
                        <p>For us privacy for our valued customer is very important. We strongly believe that the personal information of our customers should not be shared with the third party without the prior consent or request from the customer. Privacy is the right of an individual and at www.purityharvest.in the information of the customer such as contact no., email, addresses etc. is used only for the internal purpose and not for sale. Your contact information is stored in our database and is only used to contact you during the course of your stay with us for sharing the status of your room bookings with us and then after for announcement of our latest deals and news etc. We at www.purityharvest.in condemn the unauthorized reach and misuse and/or discloser of the personal information of the customer and we have strict guidelines and high security features to prevent the same. Any changes in our ‘Privacy Policy’ will be posted here on the website.</p>

                        <h3>Secure Online Payments</h3>
                        <p>The visitor on our website please take a note that your name, email address and other personal information submitted on our website may be stored with us and may also appear on the website. Like other platforms our server log files also receives general information such as IP address of the visitor, cookie etc. For the financial transactions by credit card www.purityharvest.in uses a 3rd party secure payment gateway provided by “CashFree” and the credit card details are ‘not stored’ www.purityharvest.in, instead the information is securely stored and encrypted with Visa/MasterCard.
                        </p>
                        <h3>Use of Your Information</h3>
                        <p>
                            All information that you share with us as part of using our services is available only with Farmery. We never share individual information about our users with our partners and service providers or any other third-party except in cases where it becomes imperative to share select information to provide a certain service. We may also use your information to e-mail you about other products or services that we think may be of interest to you.</p>
                        <p>We also use your personal information to personalize our service for you and to improve your overall experience at Farmery. Additionally, we use the information to promote services to you. We also use the information for product improvement purposes, troubleshooting issues, for our and your safety and for protection against error, fraud, and crimes. We may share this information with other corporate affiliates and entities for the same reasons mentioned above or as legally required by law.</p>
                        <h3>Smart Cookies</h3>
                        <p>  We use ‘Cookies’ to keep track of your current shopping session to personalize your experience and so that you may retrieve your shopping cart at any time. ‘Cookies’ are tiny text files which our Website places on your computer’s hard drive to store information about your shopping session and to identify your computer.</p>

                    </div>
                </div>
            </section>

        </>
    )
}
