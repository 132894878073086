import React, { useState } from 'react'
import product_data from '../../data/product_data';
import { Link } from 'react-router-dom';

const categories = ["All", ...Array.from(new Set(product_data.map((item) => item.category)))];

export default function ProductListArea() {

  const [activeCategory, setActiveCategory] = useState("All");
  const [items, setItems] = useState(product_data);

  const filterItems = (cateItem: string) => {
    setActiveCategory(cateItem);
    if (cateItem === "All") {
      return setItems(product_data);
    } else {
      const findItems = product_data.filter((findItem) => {
        return findItem.category == cateItem;
      });
      setItems(findItems);
    }
  };

  // handle price 

  const all_data = product_data
  const maxPrice = all_data.reduce((max, item) => {
    return item.price > max ? item.price : max;
  }, 0);
  const [priceValue, setPriceValue] = useState([0, maxPrice]);


  const handleChanges = (val: number[]) => {
    setPriceValue(val)
  }

  return (
    <>
      <section className="product-list-section overflow-hidden section-padding white-bg product-home">
        <div className="container">
          <div className="row g-xl-4 g-3 justify-content-center">
            <div className="col-lg-12">
              <div className="filter-mixtup">
                <div className="mixtup-filtering d-flex justify-content-center mb-60">
                  <div className="filter-btns">
                    {categories.map((cate, i) => (
                      <React.Fragment key={i}>
                        <button
                          onClick={() => filterItems(cate)}
                          className={`filter-btn ${cate === activeCategory ? "active-filter" : ""}`}
                        >
                          {cate}
                        </button>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="product-grid">
                  {items.map((item, i) => (
                    <div key={i} className="product-card">
                      <Link
                        to={`/product-details/${item.id}`}>
                        <img src={item.image} alt={item.title} className="product-image" />
                      </Link>
                      <div className="product-content">
                        <h3 className="product-title">{item.title}</h3>
                        <p className="product-description">{item.size} <span className='product-type'> {item.type} </span></p>
                        <div className="product-price-wrapper">
                          <span className="product-mrp">Rs {item.mrp}</span>
                          <span className="product-price">Rs {item.price}</span>
                          <span className="tax-text">* inclusive of all taxes</span>
                        </div>
                        <Link
                          to={`/product-details/${item.id}`}
                          className="order-now-btn"
                        >
                          Order Now
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
