import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

const FloatingButton: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setIsModalOpen(false);
  };

  // Open modal
  const handleOpenModal = () => setIsModalOpen(true);

  // Close modal
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {/* Floating Button */}
      <button 
        className="floating-button" 
        onClick={handleOpenModal}
      >
        <FontAwesomeIcon icon={faFileAlt} size="sm" />
      </button>

      {/* Modal */}
      <Modal 
        show={isModalOpen} 
        onHide={handleCloseModal} 
        centered 
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Get a Sample
          <p className="modal-subheading">Add details & we will call back to schedule a delivery</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label className="form-label">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
                className="form-input"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label className="form-label">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                className="form-input"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label className="form-label">Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Enter your message"
                className="form-input"
              />
            </Form.Group>
            <Button 
              variant="primary" 
              type="submit" 
              className="form-submit-btn"
              style={{border: 'none'}}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FloatingButton;