
import React from 'react'
import HeaderThree from '../../layouts/headers/HeaderThree';
import Breadcrumnd from '../common/Breadcrumnd';
import Wrapper from '../../layouts/Wrapper';
import ContactInfo from './ContactInfo';
import Subscribe from '../about/Subscribe';
import Faq from '../faq/Faq';

export default function Contact() {
    return (
        <Wrapper>
            <HeaderThree />
            <Breadcrumnd title="Contact" subtitle="Contact" />
            <ContactInfo />
            <Faq />
            {/* <Subscribe /> */}
        </Wrapper>
    )
}
