
import React from 'react'
import HeaderThree from '../../layouts/headers/HeaderThree';
import Breadcrumnd from '../common/Breadcrumnd';
import Wrapper from '../../layouts/Wrapper';
import TermsAndConditions from './TermsNCondition';

export default function Terms() {
    return (
        <Wrapper>
            <HeaderThree />
            <Breadcrumnd title="Terms And Conditions" subtitle="Terms and Conditions" />
            <TermsAndConditions />
        </Wrapper>
    )
}
