import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom'; // Changed HashRouter to BrowserRouter
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import About from './components/about';
import Product from './components/products';
import ScrollToTop from './ScrollToTop';
import Footer from './layouts/footers/Footer';
import Contact from './components/contact';
import ProductDetails from './components/product-detail';
import { CartProvider } from './context/CartContext';
import FloatingButton from './components/common/FloatingButton';
import CartFloatingButton from './components/common/CartFloatingButton';
import Terms from './components/termsncondition';
import Privacy from './components/privacyPolicy';
import RouteChangeTracker from './RouteChangeTracker';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function MainApp() {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  return (
    <React.StrictMode>
      <CartProvider>
        <BrowserRouter>
          <RouteChangeTracker />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about" element={<About />} />
            <Route path="/product-list" element={<Product />} />
            <Route path="/product-details/:productId" element={<ProductDetails />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
          </Routes>
          <Footer />

          <div className={`floating-button-container ${isOffcanvasOpen ? 'hide' : ''}`}>
            <CartFloatingButton onToggleOffcanvas={setIsOffcanvasOpen} />
            <FloatingButton />
          </div>

        </BrowserRouter> {/* Closing BrowserRouter */}
      </CartProvider>
    </React.StrictMode>
  );
}

root.render(
  <MainApp />
);

reportWebVitals();