

import React from 'react'

export default function TermsAndConditions() {
    return (
        <>
            <section className="contact-infosectionv1 space-top overflow-hidden space-bottom">
                <div className="container">
                    <div className="row g-lg-4 g-3 justify-content-center">
                        <h3>Terms and Conditions</h3>
                        <p>Welcome to Purity Harvest (http://www.purityharvest.in), a brand of Sarti Agri and Dairy Farms Please carefully review the following terms and conditions, as your use of the Purity Harvest website, www.purityharvest.in (referred to as “Purity Harvest”), and the products and services provided by us are contingent upon your acceptance and adherence to these terms (hereinafter referred to as “Terms”). By accessing, subscribing to, or using any of our services, you acknowledge that you have read, understood, and agreed to these terms, regardless of your method of engagement with our goods and services. Simply using the website signifies your agreement to contract with Sarti Agri and Dairy Farms.</p>

                        <p>In this document, references to the “Agreement” pertain to these Terms and Conditions, the Privacy Policy, any order forms, and payment instructions provided to you; “Privacy Policy” refers to the policy available on our website detailing our collection and storage of your personal data; “you,” “your,” and “yours” denote the individual accessing this website and ordering any goods or services; “we,” “us,” “our,” and “Purity Harvest” indicate Purity Harvest; “Goods” refers to any products we may offer for sale on our website; “Service” or “Services” pertains to any services we supply that you may request through our website; “Partner” or “Partners” signifies a third party that collaborates with Purity Harvest to prepare and/or deliver goods or services; “Food Delivery” refers to perishable items and services provided by our Partners, who assume full responsibility; “Website” denotes our website http://www.purityharvest.in or any mobile applications where we offer our goods or services.
                        </p>

                        <h3>User Account and Registration Terms</h3>
                        <p>Registering at Purity Harvest is free, and you will receive a username and password upon completing the registration process or a transaction. You are responsible for maintaining the confidentiality of your username and password and for all activities that occur under your account. You agree to:
                        </p>
                        <p>Registering at Purity Harvest is free, and you will receive a username and password upon completing the registration process or a transaction. You are responsible for maintaining the confidentiality of your username and password and for all activities that occur under your account. You agree to:
                        </p>
                        <p>Purity Harvest cannot be held liable for any loss or damage resulting from your failure to comply with these requirements. If you provide any false, inaccurate, outdated, or incomplete information, or if Purity Harvest has reasonable grounds to suspect such, we reserve the right to suspend, terminate, or block your access to our services.
                        </p>
                        <p>
                            As a guest/non-registered user, you agree that upon reviewing your order on our website, an account will be automatically created for you, and access details will be sent to the email you provided.
                        </p>
                        <h3> Ordering and Payment
                        </h3>
                        <p>
                            You agree to exercise caution when providing your details, ensuring that they are accurate and complete at the time of ordering. You also confirm that the credit or debit card information you provide is yours and that you have sufficient funds for the payment.
                        </p>
                        <p>
                            You authorize us to use, store, or otherwise process your personal information for the purposes of providing goods or services and delivery, as well as for marketing and credit control (the "Purpose"). This may include sharing your personal information with selected third parties as required by law or to facilitate the provision of services to you. For more details, please refer to our Privacy Policy.
                        </p>
                        <p>
                            Goods and services purchased from Purity Harvest are for your personal use only, and you affirm that any items bought are not for resale and that you are acting as the principal when receiving the services. Please check that any product you order is appropriate for the intended recipient, as some goods may have specific age requirements.
                        </p>
                        <p>
                            We will take all reasonable steps to ensure your order and payment details are secure; however, in the absence of negligence on our part, we cannot be held responsible for any loss you incur if a third party gains unauthorized access to any data you provide while accessing or ordering from Purity Harvest.
                        </p>
                        <p>
                            All orders are subject to product availability, delivery capability, and acceptance by us and our Partners. After placing an order online, you will receive an email confirming receipt of your order. This confirmation is automatically generated for your records. If any details are incorrect, please inform us immediately. Receipt of an automatic confirmation does not guarantee that we or our Partners can fulfill your order. After sending the confirmation email, we will verify availability and delivery capability. If the ordered goods, services, and delivery capacity are available, our Partner will accept the contract and confirm it to Purity Harvest. If your order details are correct, the contract for the goods or services will be confirmed via text message (SMS). In the event that goods or services are unavailable, or if there is insufficient delivery capacity, we will notify you by text message (SMS) or phone call.
                        </p>
                        <p>
                            All prices on the website are listed in Indian Rupees and include applicable taxes where necessary. The total cost for goods or services, including delivery charges, will be displayed on the Purity Harvest website when you place your order. Full payment is required for all dispatched goods and provided services. Payment can be made in cash or, where available, through online methods such as credit or debit card and net banking.
                        </p>
                        <p>
                            For online payments, you must complete payment before delivery. To ensure secure online shopping, your payment details will be encrypted to protect against unauthorized access during transmission. Your credit card company or bank may also perform security checks to confirm your identity when placing an order.
                        </p>
                        <h3>Delivery</h3>
                        <p>
                            Purity Harvest will only process orders after payment is completed for online transactions. We will strive to deliver your order as requested, but delivery times are approximate and may vary. Goods or services will be delivered to the address specified at the time of ordering. While we aim for timely delivery, we cannot accept responsibility for delays.
                        </p>
                        <p>
                            We will not be liable for any loss resulting from late delivery. If your goods are not delivered within the estimated timeframe, you can contact us via phone or email, and we will work to ensure your order is delivered as soon as possible. In cases of late delivery, delivery charges will not be waived or refunded, and all risks associated with the goods and delivery will transfer to you upon delivery.
                        </p>
                        <p>
                            You must ensure that adequate arrangements are in place for the safe delivery and acceptance of goods or services at the time of delivery. Purity Harvest is not liable for any damage, costs, or expenses resulting from inadequate access or arrangements for delivery, and such goods or services will be considered delivered with all associated risks passing to you.
                        </p>
                        <p>
                            Ownership and risk of the orders transfer to you upon delivery by Purity Harvest’s personnel and your acceptance of the order. Every effort will be made to deliver to the correct recipient; however, Purity Harvest will not be responsible for incorrect deliveries based on the address provided by you or if an individual falsely claims to be the intended recipient.
                        </p>
                        <p>

                            Please note that delivery may not be possible to some locations. If this occurs, we will notify you using the contact details you provided and arrange for cancellation or delivery to an alternate address.
                        </p>
                        <h3>
                            Communications
                        </h3>
                        <p>
                            When you use our website or communicate with Purity Harvest via email or other means, you understand that you are engaging in electronic communication. You consent to receive communications from Purity Harvest through electronic means, as required. We may communicate with you via email, SMS, phone, or other electronic or non-electronic methods. Your use of our website allows you to receive periodic updates about Purity Harvest services and promotional offers through various communication channels.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}
